<template>
  <span>
    <Tag
      v-for="(day, dayId) in programDays"
      :key="dayId"
      :label="formatDayForTag(day)"
      :selected="daySelected"
      @update="switchSelectedDay" />
    <Tag v-if="daySelected" label="Show All" @update="switchSelectedDay" />
  </span>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["programDays", "daySelected"]); // eslint-disable-line
import Tag from "@/components/Tag";
// import {useUrlHash} from "@/composables/useSetUrlHash";
// const {setUrlHash} = useUrlHash();

// Set up component elements
const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
function formatDayForTag(day) {
  let arr = day.split(",");
  return arr[0];
}

// Set up emit event
const emit = defineEmits(["update"]);
function switchSelectedDay(day) {
  log(`switchSelectedDay(${day})`);
  if (day === "Show All") day = ""; // 'Show All' button pressed
  emit("update", day); // URL hash is then updated in TimeZone.vue
}

// Set initial state
import {useRoute} from "vue-router";
const route = useRoute();
(function () {
  log("Immediately invoke function to set initial daySelected based on hash");
  let parameters = route.hash.replace(/^#/, "").split("&");
  if (route.name === "Program" && days.includes(parameters[0])) {
    log(`URL includes day '${parameters[0]}'`);
    emit("update", parameters[0]); // updates daySelected
  }
})();
</script>

<style scoped>
span {
  display: flex; /* default: flex direction = row */
  justify-content: flex-start;
  gap: 0.5rem;
}
</style>
