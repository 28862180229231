<!-- Copyright: Seán I. O'Donoghue -->
<!-- From https://github.com/mikerodham/vue-dropdowns -->
<!-- Using the 'menu button' design pattern: -->
<!-- https://www.w3.org/WAI/ARIA/apg/patterns/menubutton/ -->

<template>
  <div ref="container" class="container" @keydown.esc="showMenu = false">
    <button v-show="selected && tag" class="active" @click="select('', $event)">
      <span class="cancel"> ✕ </span>
      {{ selected }}
    </button>
    <button v-show="!selected || !tag" @click="toggleMenu()">
      <span class="caret" />
      {{ placeholder }}
    </button>
    <transition name="slide-y">
      <menu v-show="showMenu">
        <li class="title" @click="toggleMenu()">
          <span class="caret" />
          {{ placeholder }}
        </li>
        <li
          v-for="option in options"
          :key="option"
          :class="{active: option === selected}"
          @click="select(option, $event)">
          {{ option }}
        </li>
      </menu>
    </transition>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps({
  placeholder: {default: ""},
  options: {default: () => []},
  selected: {default: ""}, // option selected from parent
  tag: {default: true}, // switch to tag when selected
  closeOnOutsideClick: {default: true}
});

import {ref} from "vue";
const [showMenu, container] = [ref(false), ref(null)];
function documentClick(event) {
  const {target} = event;
  log(`documentClick(): target = ${target}`);
  if (!container.value.contains(target)) {
    log(`click is outside of menu: close the menu`);
    showMenu.value = false;
  } else {
    log(`click is inside of menu`);
  }
}
import {onMounted} from "vue";
onMounted(() => {
  if (props.closeOnOutsideClick) {
    document.addEventListener("click", documentClick);
  }
  log(`tag = ${props.tag}`);
});

function toggleMenu() {
  showMenu.value = !showMenu.value;
  log(`toggleMenu: showMenu = ${showMenu.value}`);
  log(`options = ${JSON.stringify(props.options)}`);
}

const selectedMenuItem = ref(""); // option selected in child
const emit = defineEmits(["update"]);
function select(option, event) {
  log(`select(${option}, ${JSON.stringify(event)})`);
  selectedMenuItem.value = option;
  log(`selectedMenuItem = ${selectedMenuItem.value}`);
  showMenu.value = false; // hide the menu
  if (event) {
    log(`item '${option} selected: sending it to parent`);
    emit("update", option);
  }
}

import {onBeforeUnmount} from "vue";
onBeforeUnmount(() => {
  document.removeEventListener("click", documentClick);
});
</script>

<style scoped>
div.container {
  position: relative;
  display: inline-block;
}
div + div {
  /* https://stackoverflow.com/q/8184958 */
  margin-left: 0.8rem;
}
button {
  /* position: absolute; */
  display: block;
  min-width: 6rem;
  /*padding: 12px 36px 12px 12px; /* top right bottom left */
  padding: 0.3em 0.6em 0.3em 0.6em; /* top right bottom left */
  margin: 0px;
  /* border-radius: 0.5em;
  border: 0.5px solid rgba(94, 94, 94, 0.6);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2); */
  cursor: default;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 0.5px solid var(--border-color-subtle); /* rgba(94, 94, 94, 1); */
  text-align: right;
}
.borderless button {
  border: none;
  /* box-shadow: none; */
  padding: 0px;
  color: var(--text-color);
  background-color: transparent;
}
.narrow .container,
.medium .container {
  vertical-align: 0px; /* shouldn't be needed but is! */
}
menu {
  position: absolute;
  top: -5px; /* needed to overlap button */
  right: -6px;
  z-index: 10;
  min-width: 120px;
  padding: 0px 0;
  margin-top: 3px;
  list-style: none;
  /* font-size: 14px; */
  text-align: right;
  background-color: var(--background-color); /* #FFF */
  border: 1px solid var(--border-color); /*#ccc; */
  border-radius: 0.5em;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
  background-clip: padding-box;
}
menu > li {
  display: block;
  /* position: relative; */
  margin: 0;
  list-style: none;
  padding: 2px 7px;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: var(--text-color-subtle); /* #333333; */
  text-shadow: none !important;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 0.5em;
}
menu > li:hover {
  /* color: black; */
  color: var(--primary-navigation-color) !important; /* #efefef; */
  background: var(--text-color-subtle); /* #efefef; */
  text-decoration: none;
}
.caret {
  /* position: absolute; */
  display: inline-block;
  width: 0;
  /* top: 0.7em; */
  /* left: 0.8em; */
  /* height: 0; */
  margin-right: 0.2em;
  vertical-align: middle;
  border-top: 5px dashed;
  border-top: 5px solid \9;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
span.cancel {
  display: inline-block;
  /* position: absolute;
  top: 0.3em;
  left: 0.8em; */
}
/* button:focus, */
button:hover,
button:enabled:hover {
  color: var(--text-color-subtle);
  background-color: var(--background-color-subtle);
  border: 0.5px solid var(--border-color); /* rgba(94, 94, 94, 1); */
  border-radius: 0.5em;
}
/* .borderless button:focus, */
.borderless button:hover,
.borderless button:enabled:hover {
  background-color: transparent;
  border: none;
  filter: brightness(120%);
}
/* button:focus { */
/* border: 0.5px solid var(--brand-color); */
/* box-shadow: 0 0 0 2px var(--brand-color); */
/* } */
.active {
  border-radius: 13px; /* tag only */
}
.active,
li.active,
.active:enabled:hover {
  color: white;
  font-weight: 400;
  cursor: pointer;
}
.active,
.active:enabled:hover {
  background: var(--brand-color);
  border: 0.5px solid var(--brand-color);
}
menu > li.title {
  color: var(--brand-color) !important;
}
menu > li.title:hover {
  background-color: transparent;
  border: none;
}
</style>
