// Returns currently selected year

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line

import {useRoute} from "vue-router";
import {ref} from "vue";
import {watch} from "vue";
import history from "@/assets/history.json";
// update the selected year whenever the route changes
export function useYear() {
  const route = useRoute();
  const year = ref(route.params.year);
  const timeZone = ref(year.value ? history.years[year.value] : "test");
  log(`selectedYear: initially = ${year.value}`);
  function updateSelectedYear() {
    log(`route.path = ${route.path}`);
    if (!route.path || !route.path.match(/^\/\d\d\d\d/)) return "";
    if (!route.params || !route.params.year) return "";
    return route.params.year;
  }
  watch(
    route,
    () => {
      // log(`selectedYear: route year = ${route.params.year}`);
      year.value = updateSelectedYear();
      timeZone.value = year.value ? history.years[year.value] : "test";
      log(`selectedYear: ${year.value}`);
    },
    {deep: true}
  );
  return [year, timeZone];
}
