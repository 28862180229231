<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <figcaption>
    <small v-if="flyerHiRes || flyerLowRes || posterHiRes || posterLowRes || assets">
      Download:
      <a v-if="assets" :href="`/${selectedYear}/${assets}`">VIZBI Media Kit</a>
      <a v-else-if="mediaKit" :href="mediaKit">VIZBI Media Kit</a>
      <span
        v-if="(assets || mediaKit) && (flyerLowRes || flyerHiRes || posterLowRes || posterHiRes)">
        |
      </span>
      <a v-if="flyerLowRes" :href="`/${selectedYear}/${flyerLowRes}`">Low-res flyer</a>
      <span v-if="flyerLowRes && flyerHiRes"> | </span>
      <a v-if="flyerHiRes" :href="`/${selectedYear}/${flyerHiRes}`">Hi-res flyer</a>
      <span v-if="(flyerLowRes || flyerHiRes) && posterLowRes"> | </span>
      <a v-if="posterLowRes" :href="`/${selectedYear}/${posterLowRes}`">Low-res poster</a>
      <span v-if="(flyerLowRes || flyerHiRes || posterLowRes) && posterHiRes"> | </span>
      <a v-if="posterHiRes" :href="`/${selectedYear}/${posterHiRes}`">Hi-res poster</a>.
    </small>
    <small v-if="credit" class="credit">&#169; {{ credit }}</small>
  </figcaption>
  <hr v-if="flyerHiRes || assets" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps([
  "flyerLowRes",
  "flyerHiRes",
  "posterLowRes",
  "posterHiRes",
  "assets",
  "mediaKit",
  "credit"
]);
import {inject} from "vue";
const selectedYear = inject("selectedYear");
</script>

<style scoped>
figcaption {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 8px;
}
small {
  display: inline; /* Makes the element inline with block capabilities */
}
small.credit {
  margin-left: 0.5rem; /* Pushes the credit to the right */
}
hr {
  color: var(--border-color-subtle); /* Legacy browsers */
  background-color: var(--border-color-subtle); /* Most browsers */
  border: none;
  height: 0.5px;
}
br,
hr {
  clear: both; /* clear float */
}
</style>
