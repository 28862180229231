<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <li v-if="to" :class="{selected: selected}">
    <router-link :to="to">
      <slot />
    </router-link>
  </li>
  <li v-else :class="{selected: selected}">
    <a>
      <!-- "Archive" has no link but needs '<a></a>' for consistent styling -->
      <slot />
    </a>
  </li>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {computed} from "vue";
// eslint-disable-next-line
const props = defineProps({
  to: {default: ""},
  selected: {default: false}
});
const to = computed(() => {
  if (!props?.to) return "";
  if (props.to.includes("#")) return props.to;
  return `/${props.to}`;
});
</script>

<style scoped>
li {
  list-style-type: none;
  text-align: right;
  letter-spacing: 0.03em;
  border: none;
}
a:hover {
  text-decoration: none;
}
li.selected :is(a, a:hover) {
  color: var(--brand-color) !important; /* override colors from parent */
}
</style>
