<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <span v-if="to && address[to]" :class="{outstanding: outstanding}">
    <EmailEncoder
      :recipient="address[to]['recipient']"
      :domain="`${address[to]['site']}.${address[to]['domain']}`" />
  </span>
</template>

<script>
import EmailEncoder from "@/components/EmailEncoder.vue";
export default {
  name: "Email",
  components: {EmailEncoder},
  props: {
    to: {type: String, default: "", required: true},
    outstanding: {type: Boolean, default: false}
  },
  data() {
    return {
      address: {
        veronika: {
          recipient: "vhotton",
          site: "showcare",
          domain: "com"
        },
        "lisa-delgado": {
          recipient: "Lisa.Ho",
          site: "med.usc",
          domain: "edu"
        },
        "bel-hansen": {
          recipient: "bhanson",
          site: "iscb",
          domain: "org"
        },
        "christian-stolte": {
          recipient: "christian.stolte",
          site: "gmail",
          domain: "com"
        },
        "embl-events": {
          recipient: "events",
          site: "embl",
          domain: "de"
        },
        masterclass: {
          recipient: "masterclass",
          site: "vizbi",
          domain: "org"
        },
        "nathalie-sneider": {
          recipient: "nathalie.sneider",
          site: "embl",
          domain: "de"
        },
        "iscb-admin": {
          recipient: "admin",
          site: "iscb",
          domain: "org"
        },
        "vizbi-contact": {
          recipient: "contact",
          site: "vizbi",
          domain: "org"
        },
        "vizbi-registration": {
          recipient: "registration",
          site: "vizbi",
          domain: "org"
        },
        "vizbi-workshop": {
          recipient: "workshop",
          site: "vizbi",
          domain: "org"
        }
      }
    };
  }
};
</script>

<style scoped>
.outstanding {
  font-weight: 700;
  font-size: 1.2em;
  margin-left: 24px;
  display: inline;
}
</style>
