<!-- Copyright: Seán I. O'Donoghue -->
<!-- TODO: refactor to SecondaryItem.vue -->

<template>
  <nav v-if="route.params.year" id="NavBar" :class="{hidden: !showNavBar}">
    <router-link
      v-for="page in Object.keys(currentYearPages)"
      :key="page"
      :to="'/' + route.params.year + '/' + page.replace('About', '')"
      :class="{page: true, selected: currentYearPages[page].selected}">
      {{ page.replace("About", route.params.year) }}
    </router-link>
  </nav>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(msg); // eslint-disable-line
import {ref, reactive, watch, onMounted, onUnmounted} from "vue";
import {useRoute} from "vue-router";
import stringify from "json-stringify-safe";
import {useEventListener} from "@vueuse/core";
const route = useRoute();
const showNavBar = ref(true);
const lastScrollPosition = ref(0);
const currentYearPages = reactive({});
const allPossiblePages = [
  "About",
  "People",
  "Program",
  "Posters",
  "Photos",
  "Masterclass",
  "VR",
  "Workshop",
  "Showcase",
  "Registration",
  "Venue",
  "Platform",
  "Sponsors",
  "Contact"
];

function pingFile(page) {
  const year = route.params.year;
  if (page === "About") {
    page = "Index.vue";
  } else {
    page = `${page}/Index.vue`;
  }
  try {
    const indexPage = require(`@/assets/years/${year}/${page}`);
    log(`Found @/assets/years/${year}/${page}`);
    log(`indexPage = ${stringify(indexPage)}`);
    if (window.location.host === "vizbi.org") {
      if (indexPage.default.props && indexPage.default.props.devOnly) {
        log(`devOnly = ${indexPage.default.props.devOnly}`);
        return false;
      }
    }
    return true;
  } catch (error) {
    log(`Did not find @/assets/years/${year}/${page}`);
    return false;
  }
}

function updatePageList(year) {
  if (!pingFile("About")) return false;
  let output = allPossiblePages;
  try {
    log(`Checking pages for ${year}`);
    output = output.filter((page) => pingFile(page));
    output = output.reduce((acc, page) => {
      acc[page] = {selected: false};
      return acc;
    }, {});
    const pageNames = Object.keys(output).toString();
    log(`Found vue files for: ${pageNames}`);
    return output;
  } catch (error) {
    console.error(`Did not find file: ${error}`);
  }
}

const pageCache = (() => {
  const cachedPages = {};
  return function (year) {
    if (!year) return;
    if (!cachedPages[year]) cachedPages[year] = updatePageList(year);
    return cachedPages[year];
  };
})();

function updateSelectionState() {
  const routeName = route.name;
  for (const page of Object.keys(currentYearPages)) {
    if (page === "About" && routeName === "AboutYear") {
      currentYearPages[page].selected = true;
    } else if (page === "Posters" && routeName === "Poster") {
      currentYearPages[page].selected = true;
    } else if (page === routeName) {
      currentYearPages[page].selected = true;
    } else {
      currentYearPages[page].selected = false;
    }
  }
}

function onScroll() {
  const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScroll < 0) return;
  if (currentScroll < 250) {
    showNavBar.value = true;
    return;
  }
  if (Math.abs(currentScroll - lastScrollPosition.value) < 50) return;
  showNavBar.value = currentScroll < lastScrollPosition.value;
  lastScrollPosition.value = currentScroll;
}

// Lifecycle hooks
onMounted(() => {
  log("NavigationSecondary mounted()");
  useEventListener(window, "scroll", onScroll);
  if (!route.params?.year) return;
  const pages = pageCache(route.params.year);
  if (!pages) return;
  Object.assign(currentYearPages, pages);
  updateSelectionState();
});

// Watch for route changes
watch(
  () => route.params.year,
  (year) => {
    if (!year) {
      log("No year in route: SecondaryNavigation should bug out");
    } else {
      const pages = pageCache(year);
      if (pages) {
        Object.keys(currentYearPages).forEach((key) => delete currentYearPages[key]);
        Object.assign(currentYearPages, pages);
        updateSelectionState();
      }
    }
  },
  {immediate: true}
);
</script>

<style scoped>
/* CSS limited to this component only */
nav {
  letter-spacing: 0.1px;
  font-weight: 700;
  /* padding: 8px 8px 8px 8px; */
  width: 100%;
  box-sizing: border-box;
  /*overflow: auto; /* prevents main content from wrapping with year */
  position: sticky;
  top: 0px;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  background-color: var(--background-color);
}
nav.hidden {
  /* box-shadow: none; */
  transform: translate3d(0, -100%, 0);
}
h2 {
  float: left;
  margin-top: 0px;
  margin-bottom: 8px;
  box-sizing: border-box;

  /* padding-bottom: 30px; */
}
h2 a {
  box-sizing: border-box;
  font-size: 44px;
  line-height: 30px;
}
nav {
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0px;
}
a {
  /* border-left: 1px solid #ccc; */
  display: inline-block;
  margin-bottom: 4px;
}
a:first-child {
  /* the first element - '2022' etc */
  /* https://www.w3schools.com/cssref/sel_firstchild.asp */
  float: left;
  border-right: 1px solid var(--text-color-subtle);
  /* border: none; */
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
a {
  color: var(--text-color-subtle); /* c0c0c0*/
  /* text-transform: uppercase; */
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  color: var(--text-color-less-subtle);
}
a {
  vertical-align: top;
}
/* sets the continent color on selection in the navigation list */
a.selected {
  color: var(--brand-color);
}
a.selected:hover {
  color: var(--brand-hover-color);
}
/* Mobile first - narrow */
.narrow a {
  font-size: 12px; /* was: 0.8rem 18px */
  line-height: 14px; /* was: 1rem  */
}
.narrow a:first-child {
  font-size: 16px; /*was: 2.5rem 44px */
  line-height: 32px; /* was: 1.7rem 30px */
  padding-right: 6px;
}
/* medium */
.medium a {
  font-size: 15px; /* was: 0.8rem 18px */
  line-height: 21px; /* was: 1rem  */
}
.medium a:first-child {
  font-size: 25px; /* was: 2.5rem 44px */
  line-height: 50px; /* was: 1.7rem 30px */
  padding-right: 8px;
}
/* wide */
.wide a {
  font-size: 17px; /* was: 0.8rem 18px */
  line-height: 22px; /* was: 1rem  */
}
.wide a:first-child {
  font-size: 30px; /* was: 2.5rem 44px */
  line-height: 30px; /* was: 1.7rem 30px */
  padding-right: 11px;
}
/* unlimited */
.unlimited a {
  font-size: 18px; /* was: 0.8rem 18px */
  line-height: 24px; /* was: 1rem  */
}
.unlimited a:first-child {
  font-size: 34px; /* was: 2.5rem 44px */
  line-height: 34px; /* was: 1.7rem 30px */
  padding-right: 12px;
}
.narrow nav,
.narrow nav {
  margin-bottom: 6px;
}
a + a {
  margin-left: 1em;
}
</style>
