// Returns the shortened / abbreviated version of a string on narrow displays

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {inject} from "vue";

export function useShort(abbreviations) {
  const displayWidth = inject("displayWidth"); // from composable/display.js
  const short = (text) => {
    if (displayWidth.value.match(/unlimited|wide/)) return text;
    log(`useShort: text = ${text}`);
    if (!abbreviations[text]) return text;
    log(`useShort: abbreviations[text] = ${abbreviations[text]}`);
    return abbreviations[text];
  };
  return {short}; // Return the function to use in components
}
