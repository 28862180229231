<!-- Copyright: Seán I. O'Donoghue -->
<!-- From https://github.com/mikerodham/vue-dropdowns -->
<!-- Using the 'menu button' design pattern: -->
<!-- https://www.w3.org/WAI/ARIA/apg/patterns/menubutton/ -->

<template>
  <div ref="container" @click="showMenu = !showMenu" @keydown.esc="showMenu = false">
    <button v-if="selected && tag" class="selected tag" @click.stop="$router.push(path)">
      {{ selected }} <span>✕</span>
    </button>
    <button v-else-if="selected && showMenu" class="brandColorText showMenu">
      <ArrowUp />
      {{ selected }}
    </button>
    <button v-else-if="selected && !showMenu" class="brandColorText">
      <ArrowDown />
      {{ selected }}
    </button>
    <button v-else :class="{showMenu: showMenu}">
      <!-- !selected && !tag -->
      <ArrowUp v-if="showMenu" /><ArrowDown v-else />
      {{ placeholder }}
    </button>
    <DropdownMenu v-show="showMenu" :options="options" :selected="selected" :path="path" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({
  placeholder: {default: ""},
  options: {default: () => []},
  selected: {default: ""}, // option selected from parent
  path: {default: ""}, // option selected from parent
  tag: {default: true}, // switch to tag when selected
  closeOnOutsideClick: {default: true}
});
import DropdownMenu from "@/components/DropdownRoutes/DropdownMenu.vue";
import ArrowUp from "@/assets/img/ArrowUp.vue";
import ArrowDown from "@/assets/img/ArrowDown.vue";
// set click to close the menu - https://vueuse.org/core/onClickOutside/
import {ref} from "vue";
const [container, showMenu] = [ref(null), ref(false)];
import {onClickOutside} from "@vueuse/core";
onClickOutside(container, () => (showMenu.value = false));
</script>

<style scoped>
div {
  z-index: 10;
  position: relative;
  display: inline-block;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  direction: ltr; /* needed to avoid overwrite */
  height: auto;
  box-sizing: border-box;
  min-width: 5rem;
  white-space: nowrap;
  margin: 0px;
  padding: 0.3em 0.6em 0.3em 0.6em; /* top right bottom left */
  border: 0.5px solid var(--border-color-subtle); /* rgba(94, 94, 94, 1); */
  text-align: center;
  color: var(--text-color);
  background-color: var(--background-color);
  cursor: default;
}
/* button:focus, */
button:hover {
  background-color: var(--background-color-subtle);
}
button.tag.selected {
  color: var(--primary-navigation-color);
  background-color: var(--brand-color);
}
/* button.tag.selected:focus, */
button.tag.selected:hover {
  background-color: var(--brand-hover-color);
}
button.brandColorText {
  font-weight: 600;
  color: var(--brand-color);
}
/* button.showMenu { */
/* outline: none; disable focus outline */
/* } */
</style>
