<!-- <router-link v-if="isRegistrationPage" to="/2025/Registration/Form">
  Begin Registration
</router-link> -->

<template>
  <div>
    <router-link v-if="isSignUpPage" to="/2025"> VIZBI 2025</router-link>
    <router-link v-else to="/SignUp">Stay Updated</router-link>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {useRoute} from "vue-router";
import {computed} from "vue";
const route = useRoute(); // access the current route object
const isSignUpPage = computed(() => route.name === "SignUp");
// const isRegistrationPage = computed(() => route.name === "Registration");
</script>

<style scoped>
a {
  white-space: nowrap;
  font-weight: bold;
  color: white;
  background-color: var(--brand-color);
  padding: 0.6rem 2.3rem;
  border-radius: 17px; /* Example value to increase rounded corners */
  cursor: pointer; /* Changes the cursor to indicate clickable items */
}
.narrow a {
  padding: 0.6rem 1.3rem;
}
/* Button-like styling for anchor tags */
a {
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Removes underline from links */
  border: none; /* Removes border */
}

/* Hover effect */
:is(a:hover, a:focus) {
  color: white;
  background-color: var(--brand-hover-color); /* Darkens */
  text-decoration: none; /* Ensures text remains not underlined on hover */
  outline: none; /* Removes outline to keep the design clean */
  cursor: pointer !important;
}
/* Active (click) effect */
a:active {
  background-color: var(--brand-hover-color); /* darken */
  cursor: pointer !important;
}
.soldOut {
  background-color: red;
}
</style>
