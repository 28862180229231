<!-- Copyright: Sandeep Kaur & Sean O'Donoghue -->

<template>
  <span />
</template>

<script>
const {DateTime} = require("luxon");
import timezoneHistory from "@/assets/history.json";
import stringify from "json-stringify-safe";
export default {
  name: "ProgramParse",
  props: {
    session: {type: String, default: ""},
    timeZoneSelected: {type: String, default: ""}
  },
  emits: ["finished"],
  data() {
    return {
      days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      daySelected: "", // day currently selected by user
      programOriginal: {},
      timeZoneLocal: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  },
  computed: {
    timeZoneConference() {
      // time zone the conference is set in; read from VIZBI history file
      if (this.$route.params.year) {
        return timezoneHistory.years[this.$route.params.year];
      } else {
        return "";
      }
    }
  },
  created() {
    this.log(`created()`);
    try {
      let theYear = this.$route.params.year;
      this.programOriginal = require(`@/assets/years/${theYear}/Program/program.json`);
    } catch (error) {
      console.error(`Could not load program file: ${error}`);
      //alert(`Could not load program file: ${error}`);
    }
  },
  mounted() {
    // re-calculate progam using the currently selected time zone
    if (Object.keys(this.programOriginal).length === 0) {
      // early return if the original program hasn't been mounted yet
      return {};
    }
    let program = this.programOriginal; // local copy of original program
    let output = {};
    let [date, day, title, previousEventEndTime, previousEventDate] = ["", "", "", "", ""];
    let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    this.log(`this.timeZoneSelected = ${this.timeZoneSelected}`);
    function col(heading) {
      // return the index of the column with this heading
      return program[0].indexOf(heading);
    }
    for (let i = 1; i < program.length; i++) {
      if (program[i][col("Date")]) {
        date = program[i][col("Date")];
        this.log(`Program/Parse: date = ${date}`);
        previousEventDate = date; // remember in case next event has no date
      } else {
        date = previousEventDate;
      }
      if (days.includes(program[i][col("Talk/Role/Event")])) {
        // if this row matches to the name of a day,
        title = program[i][col("Category")]; // remember day title
      } else {
        // a normal row (not a new day)
        let event = {}; // One event
        if (!program[i][col("Talk/Role/Event")]) {
          // skip 'events' with no 'Talk/Role/Event'
          continue; // jump to next iteration in the 'for' loop
        }
        event.ref = "event-item-" + i; // create unique ID for each event
        // Test to find if event will be streamed (returns true or false).
        event.video = program[i][col("Video")];
        event.mode = program[i][col("Mode")];
        if (event.video) event.mode = "Recorded video";
        if (event.video) this.log(`Found video=${event.video}; mode=${event.mode}`);
        event.title = program[i][col("Talk/Role/Event")];
        date = date.split("/");
        if (date.length === 3) {
          if (program[i][col("Duration/Time")]) {
            let eventTime = program[i][col("Duration/Time")];
            if (eventTime.match("-")) {
              let [start, end] = eventTime.split(/\s*-\s*/);
              this.log(`Event start: ${start}, end: ${end}`);
              start = start.split(":");
              end = end.split(":");
              event.start = DateTime.fromObject(
                {
                  year: date[2],
                  month: date[1],
                  day: date[0],
                  hour: start[0],
                  minute: start[1],
                  second: 0
                },
                {zone: this.timeZoneConference}
              );
              event.end = DateTime.fromObject(
                {
                  year: date[2],
                  month: date[1],
                  day: date[0],
                  hour: end[0],
                  minute: end[1],
                  second: 0
                },
                {zone: this.timeZoneConference}
              );
              if (this.timeZoneSelected === this.timeZoneLocal) {
                this.log(`Change to local times: ${this.timeZoneLocal}`);
                event.start = event.start.toLocal();
                event.end = event.end.toLocal();
              }
            } else {
              let duration = parseInt(program[i][col("Duration/Time")]);
              this.log(`Event duration: ${stringify(duration)}`);
              if (!previousEventEndTime) {
                console.error(`Need a previous event`);
                continue; // "jumps over" one iteration in the for loop
              }
              event.start = previousEventEndTime;
              event.end = previousEventEndTime.plus({minute: duration});
            }
            previousEventEndTime = event.end; // remember for next event
            day = this.getNameOfWeekday(event.start.weekday);
            this.log(`${day}: ${event.title}`);
          }
          if (event.title.match(/^End of /i)) {
            event.start = previousEventEndTime;
            event.end = "";
          }
          if (event.title.match(/^Bus /i)) {
            event.end = "";
          }
        }
        if (program[i][col("Talk/Role/Event")].match(/^Session/)) {
          event.type = "session";
          event.title = program[i][col("Category")];
          if (program[i][col("Name")]) {
            event.subtitle = program[i][col("Talk/Role/Event")];
          } else if (program[i][col("Venue")]) {
            event.subtitle = "Venue";
            event.venue = program[i][col("Venue")];
          }
        } else {
          if (program[i][col("Category")].match(/Keynote/i)) {
            event.type = "keynote";
          } else {
            event.type = "speaker";
          }
          event.title = program[i][col("Talk/Role/Event")];
        }
        event.name = program[i][col("Name")];
        event.url = program[i][col("URL")];
        if (program[i][col("Description")]) {
          event.description = program[i][col("Description")];
        } else if (program[i][col("Category")].match(/Breakout/i)) {
          this.log("A breakout with no description - add default");
          event.description =
            "All VIZBI participants were invited to propose and participate in two <a href='https://en.wikipedia.org/wiki/Unconference'>unconference</a>-style discussion sessions. During the breakout session, VIZBI participants are divided into 4-8 separate groups, each focused on one proposed topic. Each breakout session lasts for 90 minutes, and results in a short written summary that is immediately published on the VIZBI Program page for that year.";
        }
        // Add to event obj. here.
        if (Object.hasOwn(event, "start") && this.timeZoneSelected === this.timeZoneLocal) {
          day = this.getNameOfWeekday(event.start.toLocal().weekday);
        }
        this.log(`event = ${stringify(event)}`);
        if (event.type.match(/speaker|keynote/i) && !event.start) {
          // Only for 2023: with Drew as Showcase on Thursday and Friday
          continue; // jump to next iteration in the 'for' loop
        }
        if (this.session) {
          // when this.session is set, check the current program item
          if (!program[i][col("Category")].includes(this.session)) {
            // only add the item if it matches this.session
            continue; // jump to next iteration in the 'for' loop
          }
        }
        if (output[day]) {
          // if this day already has some events, add this one
          output[day].events.push(event);
          continue; // jump to next iteration in the 'for' loop
        } else if (event && event.start) {
          // if first event on this day with a start time
          output[day] = {};
          output[day].date =
            day +
            ", " +
            event.start.year +
            " " +
            this.monthNumberToName(event.start.month) +
            " " +
            event.start.day;
          output[day].events = [event];
          if (this.session) {
            // when session is set, use it for the day title
            title = this.session;
          }
          output[day].title = title; // day title
        }
      }
    }
    this.$emit("finished", output);
  },
  methods: {
    log(message) {
      // eslint-disable-line
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    getNameOfWeekday(weekdayNum) {
      if (weekdayNum >= 0 && weekdayNum <= this.days.length) {
        return this.days[weekdayNum - 1];
      }
    },
    monthNumberToName(monthNumber) {
      this.log(`Program/Parse: monthNumberToName(${monthNumber})`);
      const date = new Date(2000, monthNumber - 1); // -1 because JavaScript numbers from zero
      this.log(`Program/Parse: date (initially) = ${date}`);
      let month = date.toLocaleString("en-US", {month: "long"}); // ensure english
      this.log(`Program/Parse: month = ${month}`);
      return month;
    }
  }
};
</script>
