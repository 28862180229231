<template>
  <h3>Posters</h3>
  <component :is="page" />
  <!-- current year only: read program to check if vizbi already finished -->
  <ProgramParse v-if="year === currentYear" @finished="isEventFinished" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import ProgramParse from "@/components/Program/Parse.vue";
import {useRoute, useRouter} from "vue-router";
const [route, router] = [useRoute(), useRouter()];
let page, year;
import {shallowRef} from "vue";
const {DateTime} = require("luxon");
let currentYear = DateTime.now().year.toString();
try {
  if (route.params.year) {
    year = route.params.year;
    if (year < currentYear) {
      log(`past meeting: redirect to posters page`);
      router.push(`/Posters/${year}`);
    }
    page = shallowRef(require(`@/assets/years/${year}/Posters/Index.vue`).default);
  }
} catch (error) {
  console.error(`Posters.vue|${route.params.year} error: ${error}`);
}

const isEventFinished = (program) => {
  log(`program data has updated`);
  if (Object.keys(program).length === 0) return;
  log(`program data is available`);
  let lastDay = Object.keys(program).pop();
  log(`last day in program: ${lastDay}`);
  let lastEvent = program[lastDay].events.pop();
  log(`last event start time: ${lastEvent.start}`);
  if (DateTime.now() > lastEvent.start) {
    log(`last event has finished: redirect to posters page`);
    return router.push(`/Posters/${year}`);
  }
};
</script>

<style scoped>
:deep() img {
  /* applies to child components */
  max-width: 640px;
}
</style>
