<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <!-- 1. DON'T set tabindex explicitly (except to remove) -->
  <!-- 2. Set keyup listener on <button> > adds it to page focus order list -->
  <!-- 3. Set click listener on the <li> > avoids mix-up -->
  <button
    ref="buttonAndMenu"
    :class="{showMenu: open}"
    @keyup.enter.stop.prevent="open = !open"
    @keydown.esc="open = false">
    <PrimaryItem :selected="archiveSelected()" @click="open = !open">
      <span>
        <ArrowUp v-if="open" />
        <ArrowDown v-else />
        Archive
      </span>
    </PrimaryItem>
    <ArchiveMenu v-if="open" :years="years" :selected="selected" @keydown.esc="open = false" />
  </button>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({
  years: {default: []},
  selected: {default: ""}
});
log(`years = ${props.years}`);
log(`year selected = ${props.selected}`);
import ArchiveMenu from "@/components/Navigation/Archive/Menu.vue";
import PrimaryItem from "@/components/Navigation/Primary/Item.vue";
import ArrowUp from "@/assets/img/ArrowUp.vue";
import ArrowDown from "@/assets/img/ArrowDown.vue";
// set up function to determine if archive button should have brand coloring
function archiveSelected() {
  if (open.value) return false; /* turn off selection coloring when menu open */
  if (!props.years) return false;
  if (!props.selected) return false;
  log(`archive year selected? ${props.years.includes(props.selected)}`);
  return props.years.includes(props.selected);
}
// set click to close the menu - https://vueuse.org/core/onClickOutside/
import {ref} from "vue";
const [buttonAndMenu, open] = [ref(null), ref(false)];
import {onClickOutside} from "@vueuse/core";
onClickOutside(buttonAndMenu, () => (open.value = false));
// close menu + return focus to archive button after selection
import {watch} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {nextTick} from "vue";
watch(
  route,
  async () => {
    log(`route changed: ${route.name}`);
    await nextTick(() => {});
    setTimeout(() => {
      if (open.value) {
        log(`close menu`);
        open.value = false;
      }
      if (props.years.includes(props.selected)) {
        log(`move focus `);
        buttonAndMenu.value.focus(); /* focus to archive button */
      }
    }, 100);
  },
  {immediate: false}
);
</script>

<style scoped>
button {
  position: relative;
  z-index: 100;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: inherit;
  padding: 0;
  margin: 0;
}
span {
  display: inline-flex;
  align-items: center; /* vertically align arrow with text */
  gap: 0.3rem; /* between arrow and text */
}
button.showMenu {
  border-radius: 0.4rem;
  background-color: var(--text-color-less-subtle);
  background-color: rgba(255, 255, 255, 0.3);
}
button :deep(a) {
  color: var(--primary-navigation-color);
  text-shadow: rgb(0, 0, 0, 0.7) 0px 0px 6px;
  font-weight: 700;
  padding: 0.22rem; /* needed with text-shadow */
}
</style>
