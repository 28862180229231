<template>
  <a v-if="slidesUrl" :href="slidesUrl" :class="align" download>
    <img :src="pdfIcon" alt="PDF" title="Click to download slides" />
  </a>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line

import pdfIcon from "@/assets/img/pdf.svg";

import {shallowRef} from "vue";
let slidesUrl = shallowRef();

const props = defineProps(["name", "align"]); // eslint-disable-line
import {useRoute} from "vue-router";
const route = useRoute();
try {
  if (props.name && route.params.year) {
    const year = route.params.year;
    let slides = `assets/years/${year}/People/slides/${props.name}.pdf`;
    slides = slides.replace(/ /g, "_");
    slidesUrl = shallowRef(require(`@/${slides}`).default);
    log(`slides = ${slides}`);
  }
} catch (error) {
  log(`Slides.vue|${route.params.year} error: ${error}`);
}
</script>

<style scoped>
img {
  height: 14px;
  vertical-align: middle; /* Vertically align with text */
}
.narrow img {
  height: 13px;
}
a {
  margin-left: 0.5em;
}
a.left {
  margin-left: 0em;
  margin-right: 0.5em;
}
</style>
