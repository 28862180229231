<template>
  <div class="container">
    <p>
      The 14th international meeting on 'Visualizing Biological Data' (VIZBI 2024)
      {{ tense("takes", "took") }} place March 13&ndash;15 at the University of Southern California
      in Los Angeles, USA. A satellite
      <router-link to="/2024/Masterclass">Masterclass training event</router-link>
      {{ tense("takes", "took") }} place March 12. The events
      {{ tense("will also be", "were") }} streamed online.
    </p>

    <div ref="playerContainer" style="padding: 56.25% 0 0 0; position: relative">
      <iframe
        :src="videoUrl"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        title="VIZBI2024CampaignReel_v1_nobookend" />
    </div>

    <!-- files in public/2024 -->
    <!-- heroImage="Hero.jpg" -->
    <HeroImage
      flyer-low-res="Flyer.jpg"
      flyer-hi-res="Flyer.pdf"
      poster-low-res="Poster.jpg"
      poster-hi-res="Poster.pdf"
      media-kit="https://bit.ly/vizbi-media-kit"
      credit="Hana Pokojna, Christian Stolte, Seán O'Donoghue, Erez Lieberman,
    Juri Rappsilber, Alex Ritter, Luis Gutierrez-Heredia, Emmanuel Reynaud" />
    <RegisterNowWrapper />
    <p>
      VIZBI 2024 {{ tense("will feature", "featured") }} talks from 21 world-leading researchers
      showcasing visualizations transforming how life scientists view data, and driving key advances
      in molecular biology, systems biology, biomedical science, and ecology.
    </p>
    <p>
      The VIZBI meeting {{ tense("brings", "brought") }} together a diverse community, including
      bioinformaticians, data scientists, computer scientists, and experimentalists, as well as
      medical illustrators, graphic designers, and graphic artists.
    </p>
    <!--<p>All participants will have the opportunity to present a <a href="Posters/">poster and lightning talk</a> describing their work.</p> -->
    <p>
      All participants {{ tense("will have", "had") }} the opportunity to present a poster
      <!-- <router-link to="/2024/Posters">poster</router-link> -->
      and lightning talk
      <!-- <router-link :to="{name: 'Posters', hash: '#Lightning'}">lightning talk</router-link>-->
      describing their work. To see examples from previous VIZBIs, please take a look at the
      <router-link to="/Posters/2021">VIZBI poster collection</router-link>.
    </p>
    <p>
      A post-workshop
      <router-link to="/2024/Showcase">Showcase event</router-link>
      on Friday evening
      {{ tense("will highlight", "highlighted") }} life science communication in film and
      interactive media.
      <!-- Further details about the Showcase will be announced
      shortly. -->
    </p>
    <p>
      VIZBI 2024 {{ tense("is set to be", "was") }} an exciting event that
      {{ tense("will help", "helped") }} raise the standard of data visualization in the life
      sciences.
      <span v-if="!past">We hope very much you can join us!</span>
      <span v-else>
        Highlights from the meeting are available on the VIZBI
        <router-link to="/blog">blog</router-link>,
        <router-link to="/posters/2024">posters</router-link>,
        <router-link to="/photos/2024">photos</router-link>, and
        <router-link to="/videos">videos</router-link> pages as well as on our
        <a href="https://twitter.com/vizbi">Twitter timeline</a>.</span
      >
    </p>
    <p v-if="!past">
      If you are interested in promoting your organization or professional society, please contact
      us to discuss sponsorship options.
      <!-- <a href="Sponsors/">sponsorship options</a>. -->
    </p>
    <p>
      To receive updates about VIZBI, we encourage you to join the
      <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <!-- <KeyDates :items="keyDateItems" />
    <p><sup>*</sup>Registration for online participation remains open until the event begins.</p> -->
  </div>
</template>

<script setup>
import HeroImage from "@/components/HeroImage.vue";
import KeyDates from "@/components/KeyDates.vue";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";
import useTense from "@/composables/useTense";
const past = true;
const {tense} = useTense(past);

const keyDateItems = {
  "early registration": {
    daysBeforeOld: -36,
    daysBefore: -29,
    link: "Registration"
  },
  "on-site registration(*)": {
    dates: "Mar 5",
    link: "Registration"
  },
  "uploading posters & artwork": {
    daysBeforeOld: -18,
    daysBefore: -4,
    link: "Posters#submissions"
  },
  //  "Art & Biology upload": {
  //    daysBefore: -18,
  //    link: "Posters#art"
  //  },
  //  "Online registration": {
  //    daysBefore: -7,
  //    link: "Registration"
  //  },
  //  "Masterclass registration": {
  //   daysBefore: -1,
  //   link: "Registration"
  // },
  "VIZBI Masterclass": {
    dates: "Mar 12",
    link: "Masterclass"
  },
  "VIZBI Workshop": {
    dates: "Mar 13-15",
    link: "Program"
  }
};

import {ref, onMounted} from "vue";
const videoUrl =
  "https://player.vimeo.com/video/911933870?badge=0&autopause=0&player_id=0&app_id=58479";
const playerContainer = ref(null);
onMounted(async () => {
  // Dynamically import the Vimeo Player API
  const {default: Player} = await import("@vimeo/player");
  const player = new Player(playerContainer.value.querySelector("iframe"));
  // Set the volume to a normal level (e.g., 0.5) after the player is ready
  player
    .setVolume(0.5)
    .then(() => {
      // Volume successfully set
    })
    .catch((error) => {
      // An error occurred setting the volume
      console.error("Error setting volume:", error);
    });
  player.on("ended", () => {
    // Option to reload the iframe to show the thumbnail
    playerContainer.value.querySelector("iframe").src =
      playerContainer.value.querySelector("iframe").src;
  });
});
</script>
