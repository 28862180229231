// Copyright: Sean O'Donoghue
// Read program, then filter by session and/or change to local time zone

import {DateTime} from "luxon";
import stringify from "json-stringify-safe";
import {computed, watch} from "vue";

// eslint-disable-next-line
const log = function (message) {
  if (process.env.NODE_ENV === "development") {
    console.log(message); // uncomment to show logs in component
  }
};

const getNameOfWeekday = function (weekdayNum) {
  let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  if (weekdayNum >= 0 && weekdayNum <= days.length) {
    return days[weekdayNum - 1];
  }
};
const monthNumberToName = function (monthNumber) {
  var date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString("en-US", {
    month: "long"
  });
};

import timezoneHistory from "@/assets/history.json";
const timeZoneConference = computed((year) => {
  // time zone the conference is set in; read from VIZBI history file
  if (year) {
    return timezoneHistory.years[year];
  } else {
    return "";
  }
});

let timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;

const updateProgram = computed((program, timeZoneSelected, session) => {
  let output = {};
  let [date, day, title, previousEventEndTime, previousEventDate] = ["", "", "", "", ""];
  let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  log(`timeZoneSelected = ${timeZoneSelected}`);
  function col(heading) {
    // return the index of the column with this heading
    return program[0].indexOf(heading);
  }
  for (let i = 1; i < program.length; i++) {
    if (program[i][col("Date")]) {
      date = program[i][col("Date")];
      previousEventDate = date; // remember in case next event has no date
    } else {
      date = previousEventDate;
    }
    if (days.includes(program[i][col("Talk/Role/Event")])) {
      // if this row matches to the name of a day,
      title = program[i][col("Category")]; // remember day title
    } else {
      // a normal row (not a new day)
      let event = {}; // One event
      if (!program[i][col("Talk/Role/Event")]) {
        // skip 'events' with no 'Talk/Role/Event'
        continue; // jump to next iteration in the 'for' loop
      }
      event.ref = "event-item-" + i; // create unique ID for each event
      // Test to find if event will be streamed (returns true or false).
      event.mode = program[i][col("Mode")];
      event.title = program[i][col("Talk/Role/Event")];
      date = date.split("/");
      if (date.length === 3) {
        if (program[i][col("Duration/Time")]) {
          let eventTime = program[i][col("Duration/Time")];
          if (eventTime.match("-")) {
            let [start, end] = eventTime.split(/\s*-\s*/);
            log(`Event start: ${start}, end: ${end}`);
            start = start.split(":");
            end = end.split(":");
            event.start = DateTime.fromObject(
              {
                year: date[2],
                month: date[1],
                day: date[0],
                hour: start[0],
                minute: start[1],
                second: 0
              },
              {zone: timeZoneConference}
            );
            event.end = DateTime.fromObject(
              {
                year: date[2],
                month: date[1],
                day: date[0],
                hour: end[0],
                minute: end[1],
                second: 0
              },
              {zone: timeZoneConference}
            );
            if (timeZoneSelected === timeZoneLocal) {
              log(`Change to local times: ${timeZoneLocal}`);
              event.start = event.start.toLocal();
              event.end = event.end.toLocal();
            }
          } else {
            let duration = parseInt(program[i][col("Duration/Time")]);
            log(`Event duration: ${stringify(duration)}`);
            if (!previousEventEndTime) {
              console.error(`Need a previous event`);
              continue; // "jumps over" one iteration in the for loop
            }
            event.start = previousEventEndTime;
            event.end = previousEventEndTime.plus({minute: duration});
          }
          previousEventEndTime = event.end; // remember for next event
          day = getNameOfWeekday(event.start.weekday);
          log(`${day}: ${event.title}`);
        }
        if (event.title.match(/^End of /i)) {
          event.start = previousEventEndTime;
          event.end = "";
        }
        if (event.title.match(/^Bus /i)) {
          event.end = "";
        }
      }
      if (program[i][col("Talk/Role/Event")].match(/^Session/)) {
        event.type = "session";
        event.title = program[i][col("Category")];
      } else {
        if (program[i][col("Category")].match(/Keynote/i)) {
          event.type = "keynote";
        } else {
          event.type = "speaker";
        }
        event.title = program[i][col("Talk/Role/Event")];
      }
      event.name = program[i][col("Name")];
      event.url = program[i][col("URL")];
      event.public = program[i][col("Public")] === "TRUE";
      if (program[i][col("Description")]) {
        event.description = program[i][col("Description")];
      } else if (program[i][col("Category")].match(/Breakout/i)) {
        log("A breakout with no description - add default");
        event.description =
          "All VIZBI participants were invited to propose and participate in two <a href='https://en.wikipedia.org/wiki/Unconference'>unconference</a>-style discussion sessions. During the breakout session, VIZBI participants are divided into 4-8 separate groups, each focused on one proposed topic. Each breakout session lasts for 90 minutes, and results in a short written summary that is immediately published on the VIZBI Program page for that year.";
      }
      // Add to event obj. here.
      if (Object.hasOwn(event, "start") && timeZoneSelected === timeZoneLocal) {
        day = getNameOfWeekday(event.start.toLocal().weekday);
      }
      log(`event = ${stringify(event)}`);
      if (event.type.match(/speaker|keynote/i) && !event.start) {
        // Only for 2023: with Drew as Showcase on Thursday and Friday
        continue; // jump to next iteration in the 'for' loop
      }
      if (session) {
        // when session is set, check the current program item
        if (!program[i][col("Category")].includes(session)) {
          // only add the item if it matches session
          continue; // jump to next iteration in the 'for' loop
        }
      }
      if (output[day]) {
        // if this day already has some events, add this one
        output[day].events.push(event);
        continue; // jump to next iteration in the 'for' loop
      } else if (event && event.start) {
        // if first event on this day with a start time
        output[day] = {};
        output[day].date =
          day +
          ", " +
          event.start.year +
          " " +
          monthNumberToName(event.start.month) +
          " " +
          event.start.day;
        output[day].events = [event];
        if (session) {
          // when session is set, use it for the day title
          title = session;
        }
        output[day].title = title; // day title
      }
    }
  }
  return output; //$emit("finished", output);
});

import {useRoute} from "vue-router";

export function useProgram(options) {
  // first, get invocation options
  // https://www.vuemastery.com/blog/coding-better-composables-1-of-5/
  log(`useProgram(${stringify(options)})`);
  //const { session = "", timeZoneSelected = "" } = options;

  //let session = "";
  //let timeZoneSelected = "";

  // next, read the current route > year
  const route = useRoute();
  const year = computed(() => {
    if (!route || !route.params || !route.params.year) return;
    log(`year = ${route.params.year}`);
    return route.params.year;
  });

  // then ensure the original program is loaded from disk
  const programOriginal = computed(() => {
    log(`programOriginal(${year.value})`);
    if (!year.value) return {}; // early exit
    try {
      return require(`@/assets/years/${year.value}/Program/program.json`);
      //https://stackoverflow.com/q/69696677
      //return import.meta.glob(`@/assets/years/${year.value}/Program/program.json`);
      // https://vueschool.io/lessons/glob-imports-in-vite
    } catch (error) {
      console.error(`Could not load program file: ${error}`);
      return {};
    }
  });

  //let program;
  if (Object.keys(programOriginal).length === 0) {
    log(`program not yet loaded: early exit`);
    //program = [];
  } else {
    log(`re-calculate progam using selected session and time zone`);
    //program = programOriginal;
    //return updateProgram(programOriginal, session, timeZoneSelected);
  }

  watch(year, () => {
    log(`year has changed; fetching from disk or cache`);
    //program = [year.value, 2, 3];
  });

  return {updateProgram};
}
