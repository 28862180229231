<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <Teleport to="body">
    <dialog ref="dialogRef" @click="closeDialog">
      <nav>
        <menu>
          <button id="close">✕</button>
          <PrimaryItem
            v-for="view in views"
            :key="view"
            class="primaryView"
            :to="view"
            :selected="view === selected">
            {{ view }}
          </PrimaryItem>
          <Archive id="archive" />
          <div id="mailContainer">
            <ButtonMail id="mailButton" />
            <p />
            <!--- pseudo element for spacing -->
          </div>
        </menu>
      </nav>
    </dialog>
  </Teleport>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["views", "selected"]); // eslint-disable-line
import PrimaryItem from "@/components/Navigation/Primary/Item.vue";
import Archive from "@/components/Navigation/Archive.vue";
import ButtonMail from "@/components/ButtonMail.vue";
// function to open dialog
import {ref} from "vue";
const dialogRef = ref(null); // ref to dialog HTML element
const body = document.body;
function openDialog() {
  log(`Open dialog`);
  if (!dialogRef.value) {
    console.error(`dialogRef.value is falsy`);
    return;
  }
  body.classList.add("hide-stage"); // show-hide-stage.css
  dialogRef.value.classList.add("show-curtain"); // show-hide-curtain.css
  setTimeout(() => {
    body.style.overflowX = "hidden";
    body.style.overflowY = "hidden";
    body.style.height = "100%"; //100dvh 100%
    dialogRef.value.showModal();
  }, 200);
  // history.pushState({}, null, `${location.pathname}#menu`);
  location.hash = "#menu";
}
// function to close dialog
const emit = defineEmits(["close"]);
function closeDialog(target) {
  log(`Close dialog`);
  if (target) log(`click target: ${JSON.stringify(target)}`);
  dialogRef.value.close();
  body.classList.remove("hide-stage");
  body.classList.add("show-stage"); // show-hide-stage.css
  dialogRef.value.classList.add("hide-curtain"); // show-hide-curtain.css
  setTimeout(() => {
    body.style.overflowX = "auto";
    body.style.overflowY = "auto";
    body.style.height = "auto";
    body.classList.remove("show-stage");
  }, 1000);
  emit("close");
  // history.pushState({}, null, `${location.pathname}`);
  location.hash = "";
}
// close dialog when route changes
import {watch} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
watch(
  route,
  () => {
    log(`route changed`);
    if (location.hash === "#menu") return;
    if (dialogRef.value.open) closeDialog();
  },
  {immediate: false} // don't run on first load
);
// open dialog on mount
import {onMounted} from "vue";
onMounted(() => {
  openDialog();
});
</script>

<style scoped>
/* There is no need for hover here because: */
/* (1) narrow screen (generally) don't have mouse */
/* (2) Anyway, I want to remove most hover states, following Apple design */
/* (3) Hover states greatly increase complexity, fragility, and maintenance */
/* (4) Hover states have few benefits beyond what the cursor:pointer provides */

/* * {
  background-color: rgb(100 0 0 / 0.1) !important;
} */
dialog {
  width: 100%; /* CRITICAL */
  max-width: 100%; /* CRITICAL: https://stackoverflow.com/q/75024007  */
  padding: 0.5rem 2rem;
  margin: 0;
  border: none;
  box-sizing: border-box; /* CRITICAL: https://stackoverflow.com/q/42541657 */
  height: 100dvh; /* 100dvh 100% */
  max-height: 100dvh; /* without this, black bar */
  min-height: 100dvh;
  font-size: 1.7rem;
  font-weight: 700;
  background-color: transparent;
}
nav {
  padding: 0;
  height: 100%;
  box-sizing: border-box; /* CRITICAL: https://stackoverflow.com/q/42541657 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0; /* needed to undo padding on dialog */
  margin: 0; /* removes black bar at bottom */
  box-sizing: border-box; /* CRITICAL */
  /* overflow-x: hidden; */
  overflow-y: hidden;
  height: 100dvh; /* 100dvh 100% */
  /* max-height: 100dvh; without this, black bar */
  /* min-height: 100dvh; without this, black bar */
}
menu button {
  flex: 1.5; /* two rows */
  align-self: center; /* Vertically center this item */
  margin: 0 0 0 auto;
  font-weight: 800;
  border: none;
  box-shadow: none;
  color: var(--primary-navigation-color);
  /* text-shadow: rgb(0, 0, 0) 0 0 0.375rem; */
  background-color: transparent;
}
.lightMode menu button {
  color: var(--text-color);
  /* text-shadow: none; */
  /* text-shadow: rgb(255, 255, 255, 0.7) 0 0 0.375rem; */
}
menu :deep(a) {
  /* selects only main items, not archive */
  display: inline-flex;
  color: var(--primary-navigation-color); /* override default link color */
  text-shadow: rgb(0, 0, 0) 0 0 0.375rem;
}
.lightMode menu :deep(a) {
  color: var(--text-color-less-subtle);
  /* text-shadow: none; */
  text-shadow: rgb(255, 255, 255, 0.7) 0 0 0.375rem;
}
.primaryView {
  flex: 1;
}
#archive {
  flex: 5;
  /* height: 250px; */
}
#mailContainer {
  flex: 5; /* Allow #mailContainer to grow */
  align-self: stretch; /* Expand vertically to fill available space */
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* box-sizing: border-box; /*CRITICAL: https://stackoverflow.com/q/42541657 */
}
#mailButton {
  flex: 0; /* prevent expansion */
  cursor: pointer;
}
#mailButton :deep(a) {
  display: flex; /* prevent right-align */
  /* align-self: flex-start; */
}
@media (max-height: 700px) {
  /* short phones */
  menu > * {
    flex: 0.45; /* first seven main items */
  }
  menu button {
    flex: 0.55;
  }
  dialog {
    font-size: 1.5rem;
  }
  menu :deep(a) {
    line-height: 2.2rem;
  }
  #mailContainer {
    flex: 1.8;
  }
}
@import "@/styles/show-hide-curtain.css";
</style>
