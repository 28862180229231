<!-- https://www.eventbrite.co.uk/e/1303265485149/preview/ -->
<!-- credit="World Building Media Lab, Martin Dohrn / Teramata Studios" /> -->

<template>
  <p>
    The Art & Biology Showcase features two renowned science-artists:
    <a href="https://vizbi.org/2025/People#Andy_Lomas">Andy Lomas</a>
    and
    <a href="https://vizbi.org/2025/People#Beata_Edyta_Mierzwa">Beata Mierzwa</a>. The event takes
    place 6–8pm, Friday 11 April, at
    <a href="https://maps.app.goo.gl/ertvDocvpcv6Uq7U9">The Hub</a> in Cambridge, UK. The event is
    free, but <a :href="eventbriteUrl" title="Click to register">registration is required</a>.
  </p>
  <a :href="eventbriteUrl" title="Click to register">
    <HeroImage hero-image="Showcase.jpeg" />
  </a>
  <RegisterNowWrapper />

  <p>
    Join us for an evening exploring the intersection of science and visual creativity, featuring
    two world-renowned science-artists whose work blurs the boundaries between biological discovery
    and artistic expression.
  </p>
  <p>
    <a href="https://vizbi.org/2025/People#Andy_Lomas">Andy Lomas</a> is a digital artist and former
    visual effects supervisor whose work draws on mathematics, biology, and computer science to
    simulate and visualize the growth of cellular structures. Through generative systems inspired by
    morphogenesis, his creations reveal the hidden beauty of biological forms and developmental
    processes. <a href="https://andylomas.com/">Learn more</a>.
  </p>
  <p>
    <a href="https://vizbi.org/2025/People#Beata_Edyta_Mierzwa">Dr Beata Mierzwa</a> is a molecular
    biologist and science artist who transforms discoveries from her research on cell division into
    striking visual artworks. Her illustrations and wearable designs celebrate the elegance of life
    at the microscopic scale, making complex biology accessible and inspiring to a broad audience.
    <a href="https://beatascienceart.com/">Learn more</a>.
  </p>
  <p>
    <b>Logistics:</b>
    All ages are welcome — but under-16s should be accompanied by a parent or guardian. Light
    refreshments will be provided from 6pm, and drinks available from the Hub café bar.
  </p>
  <p>
    <b>Parking:</b>
    If you plan to drive, please use the public car park near the venue (Trumpington Park and Ride).
    No parking is available on site. If you need help or instructions for wheelchair access, please
    contact the organisers.
  </p>
  <p>
    <b>Organization:</b>
    This free science outreach event is organzed by the
    <a href="https://vizbi.org/2025/">VIZBI 2025</a>, the 15th international meeting on Visualizing
    Biological Data, and hosted by Cambridge AstraZeneca.
  </p>
  <p>For any questions, please contact <Email to="vizbi-contact" />.</p>
</template>

<script setup>
import Email from "@/components/Email";
import HeroImage from "@/components/HeroImage";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";
// import ButtonBespoke from "@/components/ButtonBespoke";
// import MaskedVideo from "@/components/MaskedVideo";
//import InnerSpaceMask from ";
// const props = defineProps({devOnly: Boolean}); // hide on vizbi.org
const soldOut = false;
const eventbriteUrl = "https://www.eventbrite.com/e/1303265485149";
</script>

<style scoped>
h3 {
  display: inline;
}
h4 {
  font-weight: 800;
  font-size: 1rem;
  margin-top: 0.3rem;
}
p button {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
