import {createApp} from "vue";
import router from "./router/index.js";
import VueGtag from "vue-gtag";
import store from "./store/index.js";
import App from "./App.vue";
import "@/styles/global.css";
import metaPixel from "./plugins/meta-pixel.js";
//import history from "connect-history-api-fallback"; // fixes GitHub 404 issue
// `https://github.com/bripkens/connect-history-api-fallback`

// console.log(`main.js: routes = ${JSON.stringify(router.getRoutes())}`);

createApp(App)
  .use(router)
  .use(
    VueGtag,
    {
      config: {
        id: "G-LH4WMEWL53",
        params: {
          cookie_flags: "SameSite=None;Secure",
          cookie_domain: "auto"
        }
        // https://www.tinstar.co.uk/studio-blog/some-cookies-are-misusing-the-recommended-samesite-attribute-how-to-fix/
      }
    },
    router
  )
  .use(metaPixel, {pixelId: "3564078550405231"})
  .use(store)
  .mount("#app");
