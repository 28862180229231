<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div>
    <router-link :to="`/Videos/${vimeoHash}`">
      <figure>
        <!-- eslint-disable-next-line vue/no-template-shadow -->
        <img class="thumb" :src="smallThumbnail(options.showcases, showcase, vimeoHash)" />
        <figcaption>
          <div class="name">
            <img
              v-if="options.get_privacy(vimeoHash, showcase)"
              :src="icon(showcase)"
              class="lock" />
            {{ options.showcases[showcase]["videos"][vimeoHash].name }}
          </div>
        </figcaption>
      </figure>
    </router-link>
    <span>
      <Tag :label="showcase.match(/\d+/)[0]" :selected="options.year" @update="filterByYear" />
      <Tag
        :label="options.showcases[showcase]['videos'][vimeoHash].tag"
        :selected="options.session"
        @update="filterBySession" />
    </span>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({
  options: {default: {}},
  showcase: {default: ""},
  vimeoHash: {default: ""}
});
const emit = defineEmits(["filterByYear", "filterBySession"]);
import Tag from "@/components/Tag";
// function to select lock icon
function icon(showcase) {
  let file = "";
  if (
    window.localStorage.loginToken &&
    window.localStorage.events &&
    window.localStorage.events.includes(showcase)
  ) {
    file = "unlocked.png";
  } else {
    file = "lock-orange.png";
  }
  return require(`@/assets/img/${file}`);
}
// function to retrieve 2nd thumbnail image for a Vimeo video
function smallThumbnail(filteredShowcases, showcase, vimeoHash) {
  if (filteredShowcases[showcase]) {
    return (
      filteredShowcases[showcase]["videos"][vimeoHash]["pictures"]["base_link"] +
      filteredShowcases[showcase]["videos"][vimeoHash]["pictures"]["sizes"][2]
    );
  }
}
function filterByYear(year) {
  emit("filterByYear", year);
}
function filterBySession(session) {
  emit("filterBySession", session);
}
</script>

<style scoped>
div a {
  color: var(--text-color);
}
div a:hover {
  color: var(--link-color-subtle);
}
figure {
  cursor: pointer;
  /*display: inline-table; */
  /* https://stackoverflow.com/q/30686191 */
  margin: 0px;
  padding: 0px;
  /* ideally, automatically switch to hi-res thumbnails on large screens */
  max-width: 100%; /* https://css-tricks.com/preventing-a-grid-blowout/ */
  flex: 1;
}
figure img.thumb {
  width: 100%;
}
figure img.thumb:hover {
  filter: brightness(105%);
}
img.lock {
  width: 0.8rem;
}

button {
  font-size: 9.9px;
}
button + button {
  margin-left: 0.5rem; /* space between individual video tags */
}
</style>
