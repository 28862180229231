<!-- Component to test importing FBX -->

<template><div ref="container" class="viewer" /></template>

<script setup>
import {onMounted, ref} from "vue";
import * as THREE from "three";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader.js";
const container = ref(null);
const fileId = "1tDyISz5GLPVkhTbD37dxRi0seAGWjqRP";

function animate(renderer, scene, camera) {
  requestAnimationFrame(() => animate(renderer, scene, camera));
  renderer.render(scene, camera);
}

async function fetchFbxFile() {
  // const apiUrl = `https://localhost:3000/api/v1/hosted/${fileId}`;
  const apiUrl = `https://localhost:3000/api/v1/local/vizbi_hero_25.fbx`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch FBX file: ${response.statusText}`);
    }
    const blob = await response.blob();
    console.log("Fetched Blob:", blob); // Debugging: Check the Blob
    const url = URL.createObjectURL(blob);
    console.log("Blob URL:", url); // Log the Blob URL
    return url;
  } catch (error) {
    console.error("Error fetching FBX file:", error);
    return null;
  }
}

const initViewer = async () => {
  const scene = new THREE.Scene();
  const aspectRatio = window.innerWidth / window.innerHeight;
  const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.value.appendChild(renderer.domElement);
  const loader = new FBXLoader();
  const fileUrl = await fetchFbxFile(); // Use the helper function
  loader.load(fileUrl, scene.add.bind(scene));
  camera.position.z = 5;
  animate(renderer, scene, camera);
};

onMounted(initViewer);
</script>

<style>
.viewer {
  width: 100%;
  height: 100%;
}
</style>
