<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <img :src="headshot" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Headshot: ${msg}`); // eslint-disable-line
import {ref, onMounted} from "vue";
import {useRoute} from "vue-router";

// Props
const props = defineProps({
  person: {type: String, default: ""},
  folder: {type: String, default: ""},
  extension: {type: String, default: "png"}
});

const route = useRoute();
const headshot = ref();

onMounted(async () => {
  let personName = props.person.replaceAll(" ", "_"); // space > underscore
  if (props.folder) {
    personName = `${props.folder}/${personName}`;
  } else {
    if (!route || !route.params || !route.params.year) return; // Early exit
    const year = route.params.year;
    personName = `years/${year}/People/img/${personName}`;
  }
  personName = `${personName}.${props.extension}`;
  try {
    // Fetch headshot then write to headshot object
    headshot.value = await require(`@/assets/${personName}`);
    log(`headshot.value = ${headshot.value}`);
  } catch (error) {
    const unknown = personName.replace(/\/[^/]+?$/, `/Unknown.${props.extension}`);
    headshot.value = await require(`@/assets/${unknown}`);
  }
});
</script>

<style scoped>
img {
  width: 100%; /* critical, otherwise img natural size is used */
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
  cursor: pointer;
  /* removes space between img & fig caption */
  /* https://stackoverflow.com/q/29160231 */
}
</style>
