<!-- THIN SPACE: &thinsp; -->
<!-- HAIR SPACE: &#8202; -->
<!-- NONS-BREAKING-PACE SPACE: &nbsp; -->

<template>
  <span v-if="options.topic && computedTopic !== 'Session Chairs'">
    &#8202;'{{ computedTopic }}'
  </span>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Topic: ${msg}`); // eslint-disable-line
const props = defineProps(["options"]);
import {computed} from "vue";

function processSoftHyphens(input) {
  // Replace `&shy;` with the Unicode soft hyphen (\u00AD)
  // return input.replace(/&shy;/g, "\u00AD");
  const processed = input.replace(/&amp;shy;|&shy;/g, "\u00AD");
  console.log(`Topic: input = ${input}`);
  console.log(`Topic: processed = ${processed}`);
  return processed;
}

function mergeMultiPartTopics(topics) {
  if (topics.length === 0) return null;
  // List of regex patterns to detect multipart labels
  const partRegexes = [
    /[\s\-–—]*(Part\s*\d+)$/i, // Matches " - Part X"
    /\s*\(\d+\/\d+\)$/, // Matches " (X/Y)"
    /\s*\[\d+\/\d+\]$/ // Matches " [X/Y]"
  ];
  // Iteratively apply each regex to remove multipart labels
  for (const regex of partRegexes) {
    topics = topics.map((t) => t.replace(regex, "").trim());
  }
  return [...new Set(topics)].join("; ");
}

const computedTopic = computed(() => {
  const {record, category, topic} = props.options;
  if (!record.Category) return console.error(`Shouldn't happen: no categories in record`);
  const categories = record.Category.split(/\s*;\s*/);
  const thisCategoryIndex = category ? categories.indexOf(category) : 0; //  first category
  if (thisCategoryIndex === -1) return console.error(`Shouldn't happen: missing category`);
  const processedTopic = processSoftHyphens(topic);
  const topics = processedTopic.split(/\s*;\s*/).map((t) => t.trim()); // Split multiple topics
  return mergeMultiPartTopics(topics);
});
</script>

<style scoped>
p {
  background-color: blue;
  width: 185px; /* Set a fixed width for testing */
}
span {
  /* display: inline-block; */
  /* word-wrap: break-word; Allows breaking long words */
  word-break: break-word; /* Ensures proper breaking for long words */
  word-wrap: break-word; /* Breaks long words to fit */
  word-break: break-word; /* Ensures long words are broken */
  hyphens: manual; /* Explicitly show hyphens where soft hyphens are present */
  letter-spacing: -0.27px;
  font-weight: 300;
  font-size: inherit;
}
</style>
