<template>
  <h3>{{ pageTitle }}</h3>
  <ProgramTable :show-day-tags="true" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import history from "@/assets/history.json";
import {computed} from "vue";
import ProgramTable from "@/components/Program/Table.vue";
const pageTitle = computed(() => {
  let pageTitle = "Program";
  if (history.programFinalized) return pageTitle;
  return `Preliminary ${pageTitle}`;
});
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
</style>
