<template>
  <span v-if="props.options.showTags && splitTags.length">
    <Tag
      v-for="(splitTag, index) in splitTags"
      :key="index"
      :label="splitTag.tag"
      :selected="splitTag?.selected"
      @update="emit('update', splitTag.tag)" />
  </span>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["options"]);
const emit = defineEmits(["update"]);
import {computed} from "vue";
import Tag from "@/components/Tag";

// Compute the tags by splitting the record.category
const splitTags = computed(() => {
  const result = [];
  for (const item of props.options.tags) {
    log(`TagList: item = ${JSON.stringify(item)}`);
    if (!item?.tag || typeof item.tag !== "string") continue; // no tag
    const singleSession = !item.tag.includes(";");
    if (singleSession) result.push(item);
    if (singleSession) continue;
    const splitTags = item.tag.split(/\s*;\s*/);
    for (const splitTag of splitTags) {
      result.push({...item, tag: splitTag.trim()});
    }
  }
  return result;
});
</script>

<style scoped>
span {
  /* Moved from figcaption span.tags in the original code */
  /* all: unset; */
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start;
  align-items: flex-start; */
  flex-wrap: wrap;
  gap: 0.1em 0.5em; /* Vertical and horizontal spacing */
}
span button {
  hyphens: none;
  font-size: 9.9px;
}
</style>
