<!-- to="/2025/Registration/Form?code=VIZBI-EARLY-BIRD"> -->
<!-- <router-link v-if="soldOut || route.name === 'Showcase'" to="/2025/Registration" class="soldOut">
  Sold Out
</router-link> -->

<template>
  <router-link v-if="soldOut" to="/2025/Registration" class="soldOut"> Sold Out </router-link>
  <a v-else-if="route.name === 'Showcase'" href="https://www.eventbrite.com/e/1303265485149"
    >Register Now</a
  >
  <router-link v-else-if="route.name === 'Registration'" :to="`/2025/Registration/Form${query}`">
    Begin Registration
  </router-link>
  <router-link v-else to="/2025/Registration">Register Now</router-link>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["soldOut"]); // eslint-disable-line
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useDiscounts} from "@/composables/useDiscounts.js";
const route = useRoute(); // access the current route object
const discounts = useDiscounts(); // discounts is a shallow ref, so needs .value
const query = computed(() => {
  if (!discounts.value) return ""; // Ensure discounts are loaded
  // Find the first key that contains "regional" (case insensitive)
  const matchKey = Object.keys(discounts.value).find((key) =>
    key.toLowerCase().includes("regional")
  );
  if (!matchKey) return ""; // No matching key found
  const discountValue = discounts.value[matchKey];
  // Map discount values to corresponding codes
  const discountCodeMap = {
    0.95: "REGION-LOW", // Low Income
    0.8: "REGION-LOMID", // Low Medium Income
    0.4: "REGION-UPMID" // Upper Medium Income
  };
  return discountCodeMap[discountValue] ? `?code=${discountCodeMap[discountValue]}` : "";
});
</script>

<style scoped>
a {
  /* font-size: 1.73rem; */
  white-space: nowrap;
  font-weight: bold;
  color: white;
  background-color: var(--brand-color);
  padding: 0.6rem 2.3rem;
  border-radius: 17px; /* Example value to increase rounded corners */
}
.narrow a {
  padding: 0.6rem 0.8rem;
}
/* Button-like styling for anchor tags */
a {
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Removes underline from links */
  border: none; /* Removes border */
  cursor: pointer; /* Changes the cursor to indicate clickable items */
}

/* Hover effect */
:is(a:hover, a:focus) {
  color: white;
  background-color: var(--brand-hover-color); /* Darkens */
  text-decoration: none; /* Ensures text remains not underlined on hover */
  outline: none; /* Removes outline to keep the design clean */
}
/* Active (click) effect */
a:active {
  background-color: var(--brand-hover-color); /* darken */
}
.soldOut {
  background-color: red;
}
</style>
