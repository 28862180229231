<!-- Copyright: Seán I. O'Donoghue -->
<!-- When finished, this will enable the user to opt-out of Google Analytics -->
<!-- It can't affect the Twitter feed - user has to set that in the browser -->
<!-- However, we should notify the user when browser settings enable cookies -->

<template>
  <div v-if="!consent" @click.stop="GdprConsent">
    <span
      >By using this site you consent to our
      <router-link to="/Terms" @click.stop.prevent>cookie policy</router-link>.</span
    >
    <ButtonBespoke @click.stop="GdprConsent">OK</ButtonBespoke>
  </div>
</template>

<script setup>
import ButtonBespoke from "@/components/ButtonBespoke";
import {ref} from "vue";
//import { $ref } from "vue/macros";
const consent = ref(localStorage.GdprConsent || false);
//const consent = $ref(localStorage.GdprConsent || false);
function GdprConsent() {
  consent.value = true;
  //consent = true;
  localStorage.GdprConsent = true;
}
// issue with $ref
// a clue re. .eslintrc.cjs: https://stackoverflow.com/q/69998772
</script>

<style scoped>
div {
  position: fixed;
  z-index: 1000; /* ensure this is above all, including <HeroVideoVimeo> */
  width: 100vw;
  bottom: 0;
  right: 0;
  text-align: center;
  background: #999999;
  cursor: pointer;
  /* font-size: 0.9rem; */
  padding: 0.5rem;
}
.narrow div {
  font-size: 0.8rem;
}
div,
a,
a:hover {
  color: white;
}
a {
  text-decoration: underline;
}
span {
  padding: 1.2rem;
}
img {
  width: 1.6rem;
  height: 1.6rem;
}
</style>
