<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <figure v-cloak :key="`${fullName}-fig`">
    <div
      v-if="highlights"
      :key="`${fullName}-placeholder`"
      class="img-placeholder"
      @click="$emit('updateHighlightedPerson', fullName)">
      <Suspense>
        <PersonHeadshot :key="`${fullName}-headshot`" :person="fullName" />
      </Suspense>
    </div>
    <a v-else :key="`${fullName}-placeholder-2`" :href="url" rel="noopener">
      <Suspense>
        <PersonHeadshot :key="`${fullName}-headshot`" :person="fullName" />
      </Suspense>
    </a>
    <figcaption>
      <!-- person's fullName -->
      <a :href="url" rel="noopener">{{ fullName }}</a>
      <span class="affiliation"> ({{ record.Affiliation }})</span>
      <Topic :options="{record, category, topic}" />
      <!-- video logo -->
      <VideoIcon :event="record" align="right" />
      <!-- pdf logo -->
      <Slides :name="fullName" />
      <br />
      <TagList :options="{tags, showTags}" @update="updateSelection" />
    </figcaption>
  </figure>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["options"]);
const {highlights, category, showTags, fullName, record, topic, url} = props.options;
import {computed} from "vue";
import Slides from "@/components/People/Person/Slides.vue";
import VideoIcon from "@/components/Program/VideoIcon";
import Topic from "@/components/People/Person/Topic.vue";
import TagList from "@/components/People/Person/TagList.vue";
import {defineAsyncComponent} from "vue";

log(`Person: fullName = ${fullName.value}`);
log(`Person: url = ${url.value}`);

const PersonHeadshot = defineAsyncComponent(() =>
  import("@/components/People/Person/Headshot.vue")
);

const speakerCategory = computed(() => record.value?.Category);
const speakerDay = computed(() => record.value?.Date);

const tags = computed(() => {
  const output = [];
  output.push({tag: speakerCategory.value, selected: props.options.category.value});
  output.push({tag: speakerDay.value, selected: props.options.day.value});
  log(`Person: tags = ${JSON.stringify(output)}`);
  return output;
});

const emits = defineEmits(["updateHighlightedPerson", "updateParentCategory", "updateParentDay"]);

function updateSelection(newSelectedTag) {
  log(`Person: newSelectedTag = ${newSelectedTag}`);
  if (speakerCategory.value.includes(newSelectedTag)) {
    const [newCategory, oldCategory] = [newSelectedTag, props.options.category.value];
    log(`Person: [newCategory, oldCategory] = [${newCategory}, ${oldCategory}]`);
    emits("updateParentCategory", newCategory === oldCategory ? null : newCategory);
  } else if (speakerDay.value.includes(newSelectedTag)) {
    const [newDay, oldDay] = [newSelectedTag, props.options.day.value];
    log(`Person: [newDay, oldDay] = [${newDay}, ${oldDay}]`);
    emits("updateParentDay", newDay === oldDay ? null : newDay);
  } else {
    console.error(`Person: unknown newSelectedTag = ${newSelectedTag}`);
  }
}
</script>

<style scoped>
figure {
  /* width: 100%; */
  min-width: 0%; /* NEW; needed for Firefox */
  margin: 0px;
  padding: 0px;
}
figcaption {
  hyphens: auto;
  caption-side: bottom;
  font-size: 13px;
  line-height: 16px;
  padding-top: 0px;
  padding-left: 0px;
  /* counter act border-spacing */
  padding-right: 0px;
  /* counter act border-spacing */
  padding-bottom: 4px; /* 8px */
  margin: 0px;
  margin-top: 5px;
  text-align: left;
}
/* https://developer.mozilla.org/en-US/docs/Web/CSS/flex-wrap*/
figcaption span {
  hyphens: auto;
  word-break: normal;
}
figcaption a {
  /* person's fullName */
  color: grey;
  font-size: 16px;
}
.narrow figcaption a {
  /* person's fullName */
  font-size: 13px;
}
.medium figcaption {
  font-size: 10px;
}
/* very medium */
.narrow figcaption {
  font-size: 10px;
}
div.img-placeholder {
  /* https://stackoverflow.com/q/1495407 */
  aspect-ratio: 167/241;
  background-color: lightgray;
}
span.affiliation {
  font-weight: 200;
}
</style>
