// https://www.youtube.com/watch?v=_k4GM5cmm68
// store: history, currentYear, currentYearPast, signIn info

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// set width category as a body class
import {ref} from "vue";
const width = ref("");
function changeWidthClass(newClass) {
  if (width.value === newClass) return;
  if (!newClass) return;
  if (width.value) document.body.classList.remove(width.value);
  width.value = newClass;
  document.body.classList.add(width.value);
}
// calculate width category
let updateScreenWidthClass = () => {
  log("updateScreenWidthClass()");
  if (window.innerWidth < 480) {
    changeWidthClass("narrow");
  } else if (window.innerWidth < 580) {
    changeWidthClass("medium");
  } else if (window.innerWidth < 768) {
    changeWidthClass("wide");
  } else {
    changeWidthClass("unlimited");
  }
  log(`updateScreenWidthClass: ${width.value}`);
};
//  run now
updateScreenWidthClass();
//  re-run upon resize
window.onresize = window.addEventListener(
  "resize",
  () => {
    updateScreenWidthClass();
  },
  true
);
//  export
export function useDisplayWidth() {
  return width;
}
