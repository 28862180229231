<!-- Copyright: Seán I. O'Donoghue -->
<!-- TODO: Enable routes for Videos/2023 and Videos/2023#Cells -->

<template>
  <!-- <p>Gallery kinda here</p> -->
  <div v-if="options && options.showcases">
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <section v-for="showcase in sortByYear(options.filteredShowcases)" :key="showcase">
      <h4>{{ showcase }}</h4>
      <div class="showcaseVideos">
        <VideoGalleryItem
          v-for="vimeoHash in Object.keys(options.filteredShowcases[showcase]['videos'])"
          :key="vimeoHash"
          :options="options"
          :showcase="showcase"
          :vimeo-hash="vimeoHash"
          @filterByYear="filterByYear"
          @filterBySession="filterBySession" />
      </div>
    </section>
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({options: {default: {}}});
const emit = defineEmits(["filterByYear", "filterBySession"]);
import VideoGalleryItem from "@/components/VideoGalleryItem";
function sortByYear(filteredShowcases) {
  let showcaseNames = Object.keys(filteredShowcases);
  return showcaseNames.sort((a, b) => {
    // remove everything that's not a number, then sort
    // https://stackoverflow.com/q/10003683
    return b.replace(/^\D+/g, "") - a.replace(/^\D+/g, "");
  });
}
function filterByYear(year) {
  emit("filterByYear", year);
}
function filterBySession(session) {
  emit("filterBySession", session);
}
</script>

<style scoped>
section div.showcaseVideos {
  /* mobile first */
  display: grid;
  gap: 16px 8px; /* Y X */
  grid-template-columns: repeat(2, 1fr); /* Creates three equal columns */
}
.medium .showcaseVideos {
  grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
}
.unlimited .showcaseVideos,
.wide .showcaseVideos {
  grid-template-columns: repeat(3, 1fr); /* Creates three equal columns */
}
</style>
