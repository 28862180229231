// Observe an HTML element and fire a callback when it changes size or content

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {useMutationObserver, useResizeObserver} from "@vueuse/core";
import {watch} from "vue";

function mountObservables(elementRef, callback) {
  log("useObservers: mountObservables"); // Initial callback execution
  if (callback) callback();
  // Set up MutationObserver
  useMutationObserver(
    elementRef,
    (mutationsList) => {
      log("useObservers: MutationObserver triggered:", mutationsList);
      if (callback) callback();
    },
    {
      childList: true,
      subtree: true,
      attributes: true
    }
  );
  // Set up ResizeObserver
  useResizeObserver(elementRef, (entries) => {
    log("useObservers: ResizeObserver triggered:", entries);
    if (callback) callback();
  });
}

export function useObservers(elementRef, callback) {
  log("useObservers: Initializing");
  watch(
    elementRef,
    (newValue) => {
      if (!newValue) return log("useObservers: elementRef is invalid");
      log("useObservers: elementRef is valide");
      mountObservables(elementRef, callback);
    },
    {immediate: true, deep: false} // Immediate to handle initial state
  );
}
