<template>
  <p>
    For inquiries relating to <b>registration, payments, or tax invoices</b>, please contact us
    using the following address:
  </p>
  <Email to="vizbi-registration" :outstanding="true" />

  <p>
    VIZBI finances are managed by the ISCB. Their address, phone number, and other details are
    provided on the
    <a :href="`${iscbUrl}/${iscbContactDetails}`">ISCB contact page</a>. If you need additional
    information about the ISCB, please contact them directly via:
  </p>
  <Email to="iscb-admin" :outstanding="true" />

  <p>
    For questions about promoting your company or organization by becoming a <b>sponsor</b>, please
    contact Veronika Hotton by schedule a meeting directly
    <a href="https://hello.showcare.com/meetings/veronika-hotton">here</a>, or by using the email
    address below:
  </p>
  <Email to="veronika" :outstanding="true" />
  <!-- <p>
    For inquiries relating to virtual logistics or online participation, please contact the
    <a href="https://keckusc.cloud-cme.com/contact/?p=35"
      >USC Office of Continuing Medical Education</a
    >
    via:
  </p>
  <Email to="lisa-delgado" :outstanding="true" /> -->
  <!-- <p>For all inquiries relating to the Masterclass please contact:</p>
  <Email to="masterclass" :outstanding="true" /> -->
  <p>For <b>all other inquiries</b>, please use the following address:</p>
  <Email to="vizbi-contact" :outstanding="true" />
</template>

<script setup>
import Email from "@/components/Email.vue";
const iscbUrl = "https://www.iscb.org";
const iscbContactDetails = "49-ISCB%20About%20Us/ISCB%20About%20Us%20Contact%20Us";
</script>

<style scoped>
p:not(:first-child) {
  margin-top: 18px;
}
</style>
