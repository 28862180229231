<!-- Show the thumbnail until it's fully loaded -->
<!-- You must pass either a valid element or a valid id -->
<!-- <figure v-if="id"> is just a safeguard -->

<template>
  <figure v-if="id" @mouseover="replayVideo" @click="replayVideo">
    <div class="wrapper">
      <img :src="thumbnailUrl" class="thumbnail" @load="createPlayer" />
      <div id="vimeo-container" />
    </div>
    <figcaption>
      <small v-if="credit" class="credit">&#169; {{ credit }}</small>
    </figcaption>
  </figure>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(`Vimeo: ${msg}`);
const props = defineProps(["id", "credit"]);
import {ref, onMounted, onUnmounted} from "vue";
import Player from "@vimeo/player";
let player;

// State variables
const thumbnailUrl = ref(""); // Store the thumbnail URL

// Fetch the video thumbnail from Vimeo API.
const fetchThumbnail = async () => {
  try {
    const apiUrl = `https://vimeo.com/api/v2/video/${props.id}.json`;
    log(`Fetching Vimeo thumbnail from: ${apiUrl}`);
    const response = await fetch(apiUrl, {mode: "cors"});
    // Detect if the request failed
    if (!response.ok) {
      throw new Error(`Failed to fetch thumbnail: HTTP ${response.status}`);
    }
    // Parse response
    const data = await response.json();
    // Log the raw response
    log("Vimeo API Response:", data);
    // Validate and set the thumbnail URL
    if (
      data &&
      Array.isArray(data) &&
      data.length > 0 &&
      typeof data[0].thumbnail_large === "string"
    ) {
      const imageUrl = data[0].thumbnail_large;
      // Extra validation: Ensure it's a valid image URL
      if (imageUrl.startsWith("http")) {
        thumbnailUrl.value = imageUrl;
        log(`Thumbnail set: ${thumbnailUrl.value}`);
      } else {
        throw new Error("Invalid image URL format.");
      }
    } else {
      throw new Error("Thumbnail data missing or in unexpected format.");
    }
  } catch (error) {
    log(`Error fetching thumbnail: ${error.message}`);
    // Set a fallback image or clear the thumbnail to prevent app homepage from showing
    thumbnailUrl.value = "";
  }
};

/* After play, reset the iframe while keeping the thumbnail visible.*/

/*  Creates the Vimeo player inside the container. */
const createPlayer = () => {
  const options = {
    id: props.id,
    autoplay: true,
    muted: true,
    background: false,
    controls: false,
    loop: false,
    responsive: true
  };

  player = new Player("vimeo-container", options);
  player
    .ready()
    .then(() => {
      log("Vimeo Player is ready!");
    })
    .catch((error) => {
      console.error("Error player.value.ready():", error);
    });

  log("Vimeo player initialized.");
};

/** Initialize the component on mount. */
// onMounted(async () => await fetchThumbnail());

onMounted(async () => {
  log(`onMounted() called with props.id = ${props.id}`);
  await fetchThumbnail();
});

// upon click or hover, replay the video
async function replayVideo() {
  log("replayVideo()");
  player
    .getPaused()
    .then((paused) => {
      if (paused) {
        log("Starting video replay");
        player.play(); // restart start playback
      } else {
        log("Video is currently playing.");
      }
    })
    .catch((error) => {
      console.error("Error checking play state:", error);
    });
}
</script>

<style scoped>
figure {
  width: 100%;
  margin: 0;
  display: block;
}

.wrapper {
  width: 100%;
  position: relative;
  border: none;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

@keyframes fadeEffect {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

/* Display the thumbnail image */
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2; /* Position above <div class="wrapper"> but below <div id="vimeo-container"> */
  opacity: 1;
  border: none;
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

/* Display the video */
#vimeo-container {
  position: absolute;
  opacity: 1; /* Slightly transparent */
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figcaption {
  margin-top: 8px;
  text-align: left;
  font-size: 0.9rem;
  color: #666;
}

.credit {
  display: block;
  margin-bottom: 4px;
  font-size: 0.8rem;
  color: #888;
}
</style>
