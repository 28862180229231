// Copyright: Seán I. O'Donoghue
// Replace Login.vue; Work out if user is logged in.
// Status: basic call works nicely; check response for
// TODO: remove Origin header > disable fetching

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
//import { call_the_backend_api } from "./call_the_backend_api.js";

export function process_the_response(response) {
  return JSON.stringify(response); // 10
}

let a = 10; //?
a;
