<template>
  <a name="top" />
  <div id="loading-indicator" :style="{display: iframeVisible ? 'none' : 'flex'}">
    Redirecting to ISCB's secure registration portal:<br />
    https://iscb.swoogo.com ...
  </div>
  <iframe
    ref="iframeElement"
    :src="registrationUrl"
    title="VIZBI 2024 Registration via Swoogo"
    :style="{display: iframeVisible ? 'block' : 'none'}"
    width="100%"
    height="1200"
    @load="checkThirdPartyCookies" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// import Email from "@/components/Email.vue";
import {ref} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
const codeValue = route?.query?.code;
const codeQuery = codeValue ? `?code=${codeValue}` : "";
const registrationUrl = `https://iscb.swoogo.com/vizbi2025${codeQuery}`;
log(`Form: registrationUrl = ${registrationUrl}`);
const iframeElement = ref(null);
const iframeVisible = ref(false);
// check if iframe is successful
function checkThirdPartyCookies() {
  return (window.location.href = registrationUrl); // immediate redirect
  // if (!iframeElement.value) return;
  // try {
  //   const iframeDoc = iframeElement.value.contentDocument || iframeElement.value.contentWindow;
  //   log(`Form: iframeDoc = ${JSON.stringify(iframeDoc, null, 4)}`);
  // } catch (error) {
  //   log(`iframe blocked: ${JSON.stringify(error, null, 4)}`);
  //   if (error.stack && error.stack.includes("localhost")) {
  //     log(`Form: Running on localhost; Open registration form directly`);
  //     window.location.href = registrationUrl;
  //     return;
  //   } else if (error.sourceURL) {
  //     log(`Form: Running on Safari; Open registration form directly`);
  //     window.location.href = registrationUrl;
  //     return;
  //   }
  //   iframeVisible.value = true;
  // }
}
</script>

<style scoped>
#loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  font-size: 2em;
  text-align: center;
  background-color: black;
  color: var(--brand-color);
  z-index: 1000;
  animation: backgroundPulse 2s linear infinite, textFlash 1s ease-in-out infinite;
}

@keyframes backgroundPulse {
  0% {
    background-color: black;
  }
  50% {
    background-color: #222;
  }
  100% {
    background-color: black;
  }
}

@keyframes textFlash {
  0% {
    color: var(--brand-color);
  }
  50% {
    color: white;
  }
  100% {
    color: var(--brand-color);
  }
}
</style>
