<template>
  <!-- v-if prevents errors when switching routes -->
  <ReplaceTweets v-if="$route.name">
    <h3>{{ `VIZBI ${route.params.year}` }}</h3>
    <component :is="page" :key="`VIZBI ${route.params.year}`" />
  </ReplaceTweets>
</template>

<script setup>
import ReplaceTweets from "@/components/ReplaceTweets.vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {shallowRef} from "vue";
let page = shallowRef();
try {
  if (route.params.year) {
    let year = route.params.year;
    page = shallowRef(require(`@/assets/years/${year}/Index.vue`).default);
  }
} catch (error) {
  console.error(`Problem with ${route.params.year}/About.vue: ${error}`);
}
</script>

<style scoped>
:deep() img {
  /* applies to child components */
  max-width: 640px;
}
</style>
