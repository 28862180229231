<!-- Copyright: Seán I. O'Donoghue -->
<!-- The meta tag should probably be in public/index.html -->
<!-- But that causes errors -->
<!-- <div v-show="host.match(/^localhost|^192/)" class="debugMessage">
    <p>to = {{ to }}</p>
    <p>step = {{ step }}</p>
  </div> -->

<template>
  <meta
    http-equiv="Content-Security-Policy"
    content="default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval'" />
  <transition v-show="to" mode="out-in" appear>
    <section v-if="step === 1">
      <h5>
        To view this
        <span v-if="host.match(/video/i)">video,</span>
        <span v-else>page,</span>
        register for
        <span v-if="typeof to === 'string'">{{ to }}</span>
        <span v-else>{{ formatStringArray(to) }}</span>
      </h5>
      <p v-show="message" class="message">{{ message }}</p>
      <div>
        <router-link to="/Registration">
          <ButtonBespoke>Register</ButtonBespoke>
        </router-link>
        <ButtonBespoke @click.once="step = 2">I already registered</ButtonBespoke>
        <!--<ButtonBespoke v-if="address">Use a different address</ButtonBespoke> -->
      </div>
    </section>
    <section v-else-if="step === 2">
      <h5>Please enter the email you used for registration:</h5>
      <p v-show="message" class="message">{{ message }}</p>
      <keep-alive>
        <input
          ref="emailInput"
          v-model="address"
          type="email"
          placeholder="Type your email address"
          @keypress.enter="verify_email(address)" />
      </keep-alive>
      <div>
        <span @click.once="[step, message] = [1, '']">
          <ButtonBespoke>Go back</ButtonBespoke>
        </span>
        <ButtonBespoke
          :key="clickCount"
          :disabled="!email_is_valid(address)"
          @click.once="verify_email(to)">
          Sign in
        </ButtonBespoke>
      </div>
    </section>
    <section v-else-if="step === 3">
      <h5>Check your email for the code to authenticate this browser:</h5>
      <p>In some cases, you may need to check your junk folder.</p>
      <input
        ref="tokenInput"
        v-model="token"
        type="text"
        autocomplete="off"
        placeholder="Paste authorisation code here" />
      <div>
        <span @click.once="[step, message] = [2, '']">
          <ButtonBespoke>Go back</ButtonBespoke>
        </span>
        <ButtonBespoke :disabled="invalid_code" @click.once="authenticate(token)"
          >Authenticate</ButtonBespoke
        >
      </div>
    </section>
    <section v-else-if="step === 4">
      <h5>This authentication code {{ tokenError }}.</h5>
      <p>Press OK to continue.</p>
      <ButtonBespoke @click.once="signedInQ()">OK</ButtonBespoke>
    </section>
  </transition>
</template>

<script>
// https://bestofvue.com/repo/syropian-vue-input-autowidth-vuejs-admin-template
import ButtonBespoke from "@/components/ButtonBespoke";
import stringify from "json-stringify-safe";
export default {
  name: "SignIn",
  components: {
    ButtonBespoke
  },
  props: {
    // name of event user logs in to
    to: {
      type: [String, Array], // can be array
      default: "" // but string is default
    }
  },
  emits: ["signedIn"], // https://stackoverflow.com/q/64220737
  data() {
    return {
      address: window.localStorage.email || "",
      clickCount: 1, // https://stackoverflow.com/q/56041297
      host: window.location.host,
      token: "", // used when user pastes token in the input field (step 3)
      tokenError: "",
      message: "",
      step: 1 // initially, start at step 1
    };
  },
  computed: {
    invalid_code() {
      return this.token.length < 64;
    },
    api() {
      let api = process.env.VUE_APP_API;
      let host = window.location.host.replace(/:\d+$/, "");
      // for a dev server, replace localhost with local network IP
      // this enables dev testing on iPhone etc.
      if (api.match(/localhost/) && host.match(/^\d+/)) {
        this.log(`this is a dev server running on a local network IP`);
        // changing from localhost to local network IP address
        api = api.replace(/localhost/, host);
      }
      return api;
    }
  },
  watch: {
    token: function () {
      // https://stackoverflow.com/q/70810463
      if (this.token) {
        // do nothing when token is removed
        this.$refs.tokenInput.style.width = this.$refs.tokenInput.value.length + "ch";
      }
    },
    address: function () {
      // https://stackoverflow.com/q/70810463
      this.$refs.emailInput.style.width = this.$refs.emailInput.value.length + "ch";
      this.message = ""; // delete message when address changes
    }
  },
  async mounted() {
    this.log(`SignIn mounted`);
    this.log(`URL path = ${this.$route.path}`);
    //let test = true;
    if (window.location.hash) {
      this.log("process token in URL");
      await this.read_token_from_URL();
    } else if (this.signedInQ()) {
      // test
      return true; // hides login dialog
    }
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    intersection(array1, array2) {
      this.log(`intersection: array1 = ${JSON.stringify(array1)}`);
      this.log(`intersection: array2 = ${JSON.stringify(array2)}`);
      if (!Array.isArray(array1)) this.log(`intersection: array1 not array`);
      if (!Array.isArray(array2)) this.log(`intersection: array2 not array`);
      if (!Array.isArray(array1) || !Array.isArray(array2)) return [];
      let result = [];
      for (let item1 of array1) {
        //this.log(`intersection: item1 = ${item1}`);
        if (array2.includes(item1)) result.push(item1);
      }
      this.log(`intersection: ${JSON.stringify(result)}`);
      return result;
    },
    signedInQ() {
      this.message = ""; // remove old error message
      this.token = ""; // remove old value
      let userEvents = {};
      if (localStorage.getItem("events")) {
        userEvents = JSON.parse(localStorage.getItem("events"));
        this.log(`localStorage.events = ${JSON.stringify(userEvents)}`);
      }
      let signedInEvents = [];
      if (Object.keys(userEvents) && Object.keys(userEvents).length > 0) {
        signedInEvents = this.intersection(Object.keys(userEvents), this.to);
        this.log(`User is signed in to ${JSON.stringify(signedInEvents)}`);
      }
      if (signedInEvents.length > 0) {
        this.log(`User is signed in to ${signedInEvents.length} events`);
        this.$emit("signedIn", signedInEvents); // notify parent component
      } else {
        this.log(`not yet signed in to ${this.to}; ask for email`);
      }
      this.log(`userEvents = ${JSON.stringify(userEvents)}`);
      this.log(`typeof userEvents = ${typeof userEvents}`);
      this.log(`userEvents[this.to] = ${userEvents[this.to]}`);
      this.step = 1;
    },
    async verify_email(to) {
      this.log(`verify_email(${to}) called.`);
      this.debug = `verify_email(${to}) called.`;
      let response = await this.verify_email_call(to);
      if (!response) {
        this.message = `VIZBI sign-in is offline; please try later`;
        return;
      }
      let responseJson = await response.json();
      if (responseJson.error) {
        this.log(`responseJson.error = ${responseJson.error}`);
        if (responseJson.error.match(/not registered for/)) {
          this.message = `Email not registered to access this video.`;
        } else {
          this.message = `No registration found for this address.`;
        }
      } else {
        window.localStorage.email = this.address;
        this.log(`All good - confirmation email should be sent.`);
        this.message = `Confirmation email has been sent.`;
        this.step = 3;
      }
      this.clickCount++; // change key > force re-render > re-enable button
      return;
    },
    email_is_valid(email) {
      // https://v2.vuejs.org/v2/cookbook/form-validation.html
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // this.log(`email = ${email}`);
      return regex.test(email);
    },
    async fetchWithTimeout(resource, options = {}) {
      // https://dmitripavlutin.com/timeout-fetch-request/
      const {timeout = 8000} = options;
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);
      const response = await fetch(resource, {
        ...options,
        signal: controller.signal
      });
      clearTimeout(id);
      return response;
    },
    async verify_email_call(to) {
      let showcases = to;
      if (typeof to === "string") {
        showcases = [to];
      }
      try {
        this.log(`verify_email_call() called.`);
        return await this.fetchWithTimeout(`${this.api}/user/verify_email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: stringify({
            email: this.address,
            path: this.$route.path,
            showcases: showcases
          })
        });
      } catch (error) {
        this.log(`Problem calling verify_email: ${error}`);
      }
    },
    async authenticate(token) {
      if (!token) {
        console.error(`authenticate() called with no token`);
        return;
      }
      this.log(`authenticate() called`);
      let response = await this.authenticate_call(token);
      response = await response.json();
      // don't remove token from fragment yet - otherwise you have a bug
      // the fragment is removed in Videos.vue/get_meta_data
      if (!response) {
        this.message = "No response from server - please try again later.";
      } else if (response.status && response.status === "success") {
        this.log(`SignIn now authenticated for browser; emit 'signedIn'`);
        window.localStorage.loginToken = response.loginToken;
        window.localStorage.events = JSON.stringify(response.events);
        this.log(`localStorage.events = ${response.events}`);
        // 'events' = VIZBI events associated with the user/email
        this.step = ""; // hides login steps
        this.$emit("signedIn"); // notify parent component
        return;
      } else if (response.error === "expired token") {
        this.log(`response.error === 'expired token'`);
        this.tokenError = "has expired";
        this.step = 4; // was 3
      } else {
        this.log(`Unknown error with authentication code`);
        this.tokenError = "is invalid";
        this.step = 4; // was 2, then 3
      }
    },
    async authenticate_call(token) {
      this.log(`authenticate_call() called`);
      this.log(`token = ${token}`);
      try {
        return await this.fetchWithTimeout(`${this.api}/user/authenticate`, {
          method: "POST",
          headers: {
            //'Accept': 'application/json',
            "Content-Type": "application/json"
          },
          body: stringify({
            token: token
          })
        });
      } catch (error) {
        this.log(`Problem verifying authentication code: ${error}`);
      }
      this.step = 4; // shows message that authentication is invalid
    },
    async read_token_from_URL() {
      if (!window.location.hash) {
        return this.log(`URL fragment is empty.`);
      }
      const token = window.location.hash.replace(/^#/, ""); // read token
      if (token) {
        this.log(`In URL fragment: token = ${token}`);
        try {
          // const response =
          await this.authenticate(token);
          this.log(`Silently remove token from hash (not trigging router)`);
          // https://stackoverflow.com/q/51337255
          history.pushState({}, null, this.$route.path);
        } catch (error) {
          this.log(`Problem verifying authentication code: ${error}`);
        }
        this.step = 3; // shows message that authentication is invalid
      }
    },
    formatStringArray(strings) {
      if (strings.length === 0) {
        return "";
      } else if (strings.length === 1) {
        return strings[0];
      } else if (strings.length === 2) {
        return `${strings[0]} or ${strings[1]}`;
      } else {
        // For three or more strings, join all but the last with commas, and append the last with 'or'
        return `${strings.slice(0, -1).join(", ")} or ${strings[strings.length - 1]}`;
      }
    }
  }
};
</script>

<style scoped>
/* https://stackoverflow.com/q/59632929 */
.v-enter-from {
  opacity: 0;
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
  /* transform: scale(1); */
}
.v-enter-active {
  transition: all 0.5s ease;
}
/* .v-move {transition: all 5s ease;} */
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
.v-leave-active {
  transition: all 0.5s ease;
}
section {
  display: flex;
  flex-direction: column;
  /* sets main axis as vertical */
  gap: 20px;
  /* vertical spacing between children */
  align-items: center;
  /* aligns children + text within children */
  background-color: #f8f8f8;
  border: 1px solid #bdbdbd;
  padding: 25px;
  /* separates border from children */
  margin-top: 25px;
  margin-bottom: 52px;
}
section > * {
  /* selects all direct children of each section */
  /* https://stackoverflow.com/q/43739470 */
  flex: 1;
  /* give each child approximately equal height */
  margin: 0px;
  padding: 0px;
  text-align: center;
}
section h5 {
  font-size: 1.05rem;
}
section p {
  font-style: italic;
}
section input {
  min-width: 16rem;
  max-width: 95%;
  padding: 0.2em;
}
section div {
  display: inline-flex;
  flex-direction: row;
  /* sets main axis as vertical */
  gap: 12px;
  /* background-color: blue; */
  /* for testing */
}
section div button {
  flex: 2;
  /* background-color: red; */
  /* for testing */
}
button,
input {
  margin: 0px;
  /* 20px */
  padding: 8px 16px;
  text-align: center;
}
.backButton {
  left: 0%;
  top: 0%;
  transform: translate(-20%, -20%);
  background: linear-gradient(to bottom, #bdbdbd 5%, #d5d5d5 100%);
  border: 1px solid #bdbdbd;
  box-shadow: inset 0px 1px 0px 0px #bdbdbd;
  text-shadow: 0px 1px 0px #bdbdbd;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}
.message,
.debugMessage {
  color: red;
}
</style>
