import {onMounted, watch} from "vue";
import {useWindowSize} from "@vueuse/core";

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line

// Helper: Calculate the parent width
function getParentWidth(element) {
  const parent = element.parentElement;
  if (!parent) {
    log("useMaxFontSize: No parent element found.");
    return 0;
  }
  return parent.offsetWidth;
}

// Helper: Dynamically calculate the maximum font size
function getMaxFontSize(parentWidth, viewportWidth) {
  return Math.min(100, parentWidth * 0.1, viewportWidth * 0.05); // Adjust max size for narrow screens
}

// Helper: Incrementally adjust to the largest possible font size with fine precision
function adjustFontSizeToFit(element, parentWidth, minFontSize, maxFontSize) {
  let low = minFontSize;
  let high = maxFontSize;
  let bestFitFontSize = minFontSize;

  // Binary search to find the largest whole-pixel font size that fits
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    element.style.fontSize = `${mid}px`;

    if (element.scrollWidth <= parentWidth) {
      bestFitFontSize = mid; // Update best fit size
      low = mid + 1; // Try larger sizes
    } else {
      high = mid - 1; // Reduce size
    }
  }

  // Apply best fit size initially
  element.style.fontSize = `${bestFitFontSize}px`;

  // Check for slight overflows with a buffer (final safeguard)
  const buffer = 1; // Allow for a 1px buffer
  if (element.scrollWidth > parentWidth - buffer) {
    bestFitFontSize--; // Revert to a slightly smaller size
    element.style.fontSize = `${bestFitFontSize}px`;
  }

  return bestFitFontSize;
}

function adjustFontSize(element) {
  if (!element) return log("useMaxFontSize: No element provided.");
  log(`useMaxFontSize: Adjusting element: ${element.tagName}`);

  // Ensure text does not wrap
  element.style.whiteSpace = "nowrap";
  element.style.overflow = "hidden";

  const parentWidth = getParentWidth(element);
  if (parentWidth <= 0) return log("useMaxFontSize: Parent width invalid.");

  const viewportWidth = window.innerWidth;
  const maxFontSize = getMaxFontSize(parentWidth, viewportWidth);
  const minFontSize = 8; // px

  const finalFontSize = adjustFontSizeToFit(element, parentWidth, minFontSize, maxFontSize);
  log(`useMaxFontSize: Final font size for element: ${finalFontSize}px`);
}

export function useMaxFontSize(element) {
  const adjust = () => {
    if (element.value) {
      adjustFontSize(element.value);
    } else {
      log("useMaxFontSize: Element reference is not available.");
    }
  };
  onMounted(() => {
    const {width} = useWindowSize(); // Reactive window size
    adjust(); // Initial adjustment
    watch(width, adjust); // React to viewport changes
  });
}
