<!-- Copyright: Sean I. O'Donoghue, Neblina Sikta -->
<!-- :date="people[fullName]?.Date" -->

<template>
  <!-- https://vuejs.org/api/built-in-directives.html#v-cloak -->
  <h4 v-show="selectedPeople.length > 0">{{ computedName }}</h4>
  <div v-if="isReady" class="gallery">
    <Person
      v-for="fullName in filteredPeople"
      v-cloak
      :key="fullName + 'fig'"
      :options="optionsForSpeaker(fullName)"
      @updateHighlightedPerson="updateHighlightedPerson"
      @updateParentCategory="updateParentCategory"
      @updateParentDay="updateParentDay" />
    <!-- Add empty figures to make fill the screen width: 3, 4, or 5 -->
    <figure v-for="ghost in ghosts" :key="ghost" :class="[ghost]" />
  </div>
  <!-- v-if="highlights"  -->
  <PersonHighlight
    :key="highlightedPerson"
    :person="highlightedPerson"
    :people="people"
    :selected-people="selectedPeople"
    :day="day"
    @hide="highlightedPerson = ''" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["category", "day", "highlights", "showTags", "name", "sort"]);
import {ref, computed, watch, onMounted} from "vue";
import {useRoute} from "vue-router";
import moment from "moment";
import history from "@/assets/history.json";
import Person from "@/components/People/Person";
import PersonHighlight from "@/components/People/Person/Highlight";
import stringify from "json-stringify-safe";
const isReady = ref(false);
const route = useRoute();
const ghosts = ref([]);
const highlightedPerson = ref("");
const people = ref({});
const peopleArray = ref([]);
const selectedPeople = ref([]);

function optionsForSpeaker(fullName) {
  log(`Gallery: fullName = ${fullName}`);
  const category = ref(props.category);
  const day = ref(props.day);
  const highlights = ref(props.highlights);
  const showTags = ref(props.showTags);
  if (!people.value || Object.keys(people.value).length === 0) return {}; // if people not set up
  const record = ref(people.value[fullName]);
  const date = ref(record.value?.Date);
  const topic = ref(record.value?.Topic);
  const url = ref(record.value?.URL);
  return {highlights, showTags, category, day, record, date, topic, url, fullName};
}

const computedName = computed(() => {
  const name = props.name;
  log(`Gallery: programFinalized = ${history.programFinalized}`);
  if (history.programFinalized) return name;
  if (name !== "Speakers") return name;
  return `Confirmed ${name}`;
});

const emit = defineEmits(["foundCategories", "foundDays", "updateCategory", "updateDay"]);

// Add missing event handler functions
const updateHighlightedPerson = (person) => {
  highlightedPerson.value = person;
};

const updateParentCategory = (category) => {
  emit("updateCategory", category);
};

const updateParentDay = (day) => {
  emit("updateDay", day);
};

const groupIsNotEmpty = computed(() => selectedPeople.value.length > 0);

// Rest of your existing functions...
const loadJSONfiles = async () => {
  try {
    let file = props.name.toLowerCase().replace(/ /, "-");
    let year = route.params.year;
    const data = await require(`@/assets/years/${year}/People/${file}.json`);
    log(`Gallery: Loaded JSON file: ${file}.json`);
    return data;
  } catch (error) {
    console.error(`/People: error fetching json: ${error}`);
  }
};

const updateSelectedPeople = () => {
  log(`Gallery: updateSelectedPeople() called`);
  if (!people.value || Object.keys(people.value).length === 0) {
    log(`Gallery: No people data available`);
    return null; // early exit when called before mount
  }
  let peopleData = people.value;
  log(`Gallery: peopleData = ${JSON.stringify(peopleData)}`);
  let selectedPeopleList = Object.keys(peopleData);
  log(`Gallery: initial selectedPeopleList = ${JSON.stringify(selectedPeopleList)}`);
  if (props.category) {
    // when a category is set, return the filtered list of people
    let category = props.category;
    selectedPeopleList = selectedPeopleList.filter((person) =>
      peopleData[person]["Category"].includes(category)
    );
  } else if (props.day) {
    // when a day is set, return the filtered list of people
    let day = props.day;
    selectedPeopleList = selectedPeopleList.filter(
      (person) => peopleData[person]["Date"] && peopleData[person]["Date"].includes(day)
    );
  } else {
    log(`Gallery: No selected session or day`);
  }
  log(`Gallery: filtered selectedPeopleList = ${JSON.stringify(selectedPeopleList)}`);
  if (props.sort === "First name") {
    // default JS sorting
    selectedPeopleList = selectedPeopleList.sort();
  } else if (props.sort === "Last name") {
    selectedPeopleList = sortLastName(selectedPeopleList);
  } else {
    // by default
    selectedPeopleList = sortCategory(selectedPeopleList);
  }
  if (selectedPeopleList.length < 5) {
    // fill one grid row with empty <figure> tags by adding 'ghosts'
    let ghostsNeeded = 5 - selectedPeopleList.length; // number of ghosts needed
    ghosts.value = []; // remove any existing 'ghosts'
    for (let i = 1; i <= ghostsNeeded; i++) {
      ghosts.value.push(`ghost-${i}`);
    }
  }
  selectedPeople.value = selectedPeopleList;
  log(`Gallery: final selectedPeople = ${JSON.stringify(selectedPeople.value)}`);
};

const formatDay = (dateString) => {
  return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
};

const regularizeCategoryNames = (person) => {
  // Rename categories to shorten name
  if (person.Category === "Cellular Systems") {
    person.Category = "Cells";
  }
  // Add more category name regularizations as needed
  return person.Category;
};

const arrayToObject = (peopleArray) => {
  if (!peopleArray || Object.keys(peopleArray).length === 0) {
    return {}; // early exit when called before mount
  }
  let people = {}; // the main output
  let properties = peopleArray[0]; // read properties from first row
  properties = properties.map((property) => property.replace(/^Talk.*/i, "Topic"));
  log(`properties = ${stringify(properties)}`);
  let rowIndex;
  // skip first row containing headers
  for (rowIndex = 1; rowIndex < peopleArray.length; rowIndex++) {
    let rowData = peopleArray[rowIndex];
    let columnIndex; // this is column count
    let name = "";
    let person = {};
    for (columnIndex = 0; columnIndex < properties.length; columnIndex++) {
      if (!rowData[columnIndex]) {
        log("warning: no data");
        continue;
      } else if (properties[columnIndex].match(/name/i)) {
        log(`Found a person's name: ${rowData[columnIndex]}`);
        name = rowData[columnIndex];
      } else if (properties[columnIndex].match(/date/i)) {
        person[properties[columnIndex]] = formatDay(rowData[columnIndex]);
      } else if (properties[columnIndex] === "Description") {
        if (rowData[columnIndex]) {
          // if there is a description for this speaker's talk
          // store it in a new object, with session name as key
          person["Description"] = {};
          person["Description"][person["Category"]] = rowData[columnIndex];
        }
      } else {
        person[properties[columnIndex]] = rowData[columnIndex];
      }
    }
    person.Category = regularizeCategoryNames(person);
    if (people[name]) {
      // if this person appears twice in this group, merge categories
      if (person["Category"] !== people[name]["Category"]) {
        // only merge if the categories are different
        person["Category"] = people[name]["Category"] + "; " + person["Category"];
      }
      // also merge dates
      if (person["Date"] !== people[name]["Date"]) {
        // but only if the dates are different
        person["Date"] = people[name]["Date"] + "; " + person["Date"];
      }
      // also merge titles
      person["Topic"] = people[name]["Topic"] + "; " + person["Topic"];
      if (people[name]["Description"]) {
        // if the previous array item for this person had a descriptions
        // store with current description in new object
        person["Description"] = {
          ...people[name]["Description"],
          ...person["Description"]
        };
      }
    }
    if (!name.match(/\?/) && !name.match(/^T.?B.?A$/i)) {
      // Exclude names with questions marks or 'T.B.A.'
      people[name] = person;
    }
  }
  if (Object.keys(people).length > 0) {
    return people;
  } else {
    return null;
  }
};

const getCategories = (people) => {
  const categories = new Set();
  Object.values(people).forEach((person) => {
    if (person?.Category) {
      const categoriesArray = Array.isArray(person.Category) ? person.Category : [person.Category];
      categoriesArray.forEach((category) => categories.add(category));
    }
  });
  return Array.from(categories).sort();
};

const getDates = (people) => {
  const dates = new Set();
  Object.values(people).forEach((person) => {
    if (person?.Date) dates.add(person.Date);
  });
  return Array.from(dates).sort();
};

const sortCategory = (selectedPeople) => {
  log(`Gallery: sortCategory()`);
  let peopleData = people.value;
  // Create separate groups for categories
  let keynotes = [];
  let masterclasses = [];
  let others = [];
  // Categorize speakers into the groups
  selectedPeople.forEach((person) => {
    if (peopleData[person]?.Category?.includes("Keynote")) {
      keynotes.push(person);
    } else if (peopleData[person]?.Category?.includes("Masterclass")) {
      masterclasses.push(person);
    } else {
      others.push(person);
    }
  });

  log(`Gallery: keynotes = ${JSON.stringify(keynotes)}`);
  log(`Gallery: masterclasses = ${JSON.stringify(masterclasses)}`);
  log(`Gallery: others = ${JSON.stringify(others)}`);

  // Combine the groups in the correct order
  const sortedSpeakers = [...keynotes, ...masterclasses, ...others];
  log(`Gallery: sortedSpeakers = ${JSON.stringify(sortedSpeakers)}`);
  return sortedSpeakers;
};

const sortLastName = (selectedPeople) => {
  log(`sortLastName()`);
  let sortedSpeakers = selectedPeople.sort((a, b) => {
    let aLastName = a.replace(/^.* /, "");
    let bLastName = b.replace(/^.* /, "");
    // https://stackoverflow.com/q/6712034
    return aLastName.localeCompare(bLastName);
  });
  return sortedSpeakers;
};

const filteredPeople = computed(() => {
  const filteredPeople = selectedPeople.value.filter(
    (fullName) => people.value[fullName] && people.value[fullName].Category
  );
  log(`Gallery: filteredPeople = ${JSON.stringify(filteredPeople)}`);
  return filteredPeople;
});

onMounted(async () => {
  try {
    peopleArray.value = await loadJSONfiles();
    if (!peopleArray.value) return;
    // log(`Gallery: peopleArray = ${JSON.stringify(peopleArray.value)}`);
    people.value = arrayToObject(peopleArray.value);
    emit("foundCategories", getCategories(people.value));
    emit("foundDays", getDates(people.value));
    updateSelectedPeople();
    isReady.value = true;
  } catch (error) {
    console.error("Failed to initialize:", error);
  }
});

// Existing watchers and lifecycle hooks...
watch(
  () => props.category,
  () => {
    updateSelectedPeople();
  }
);

watch(
  () => props.day,
  () => {
    updateSelectedPeople();
  }
);

watch(
  () => props.sort,
  () => {
    updateSelectedPeople();
  }
);
</script>

<style scoped>
/* swtichted to grid layout - will never look back! */
/* https://www.youtube.com/watch?app=desktop&v=TUD1AWZVgQ8&t=876s */
.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); /* Mobile 1st */
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  grid-row-gap: 0.4rem;
  grid-column-gap: 0.2rem;
  margin: 0px;
  padding: 0px;
}
.medium .gallery {
  grid-template-columns: repeat(auto-fit, minmax(26%, 1fr));
  grid-row-gap: 0.85rem;
  grid-column-gap: 0.3rem;
}
.wide .gallery {
  grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
  grid-row-gap: 0.7rem;
  grid-column-gap: 0.4rem;
}
.unlimited .gallery {
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr)); /* 15%=6 columns */
  grid-row-gap: 0.8rem;
  grid-column-gap: 0.5rem;
}
/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/figure */
/* https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp */
/* medium */
</style>
