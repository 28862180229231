// Determines regional discount via IP lookup, falling back to browser settings.
// Uses country income data and caches results to minimize lookups.

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import {shallowRef, onMounted} from "vue";
import history from "@/assets/history.json";
import {useRegionalDiscount} from "@/composables/useRegionalDiscount.js";
const discounts = shallowRef(null); // like ref() but only for top level object
// let discounts = {};

async function fetchDiscounts(year) {
  try {
    const module = await import(`@/assets/years/${year}/Registration/discounts.json`);
    return module.default;
  } catch (error) {
    console.error(`Fetching discounts for ${year}: ${error}`);
  }
}

async function loadDiscounts() {
  const latestYear = history.latestYear;
  log(`useDiscounts: latestYear = ${latestYear}`);
  const standardDiscounts = await fetchDiscounts(latestYear);
  log(`useDiscounts: standardDiscounts = ${JSON.stringify(standardDiscounts)}`);
  discounts.value = {...standardDiscounts};
  // Object.assign(discounts, standardDiscounts); // adds 'standardDiscounts' to 'discounts'
  const regionalDiscount = await useRegionalDiscount();
  log(`useDiscounts: regionalDiscount = ${regionalDiscount}`);
  if (regionalDiscount) discounts.value["Regional discount"] = regionalDiscount;
  log(`useDiscounts: discounts = ${JSON.stringify(discounts.value)}`);
  // return discounts;
}

export function useDiscounts() {
  if (!discounts.value) onMounted(async () => await loadDiscounts());
  return discounts;
}
