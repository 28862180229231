<!-- (3)</span> Case studies (including participant examples); -->
<!-- credit="D. Berry, B. Kozlíková, S. O'Donoghue & C. Stolte" -->

<template>
  <p>
    The VIZBI Masterclass is an intensive DataVis training event taking place 8 April in Cambridge
    UK — and online.
    <!-- You can also join satellite masterclasses in
    <a href="https://www.datavismasterclass.org/de/heidelberg">Heidelberg (2 April)</a>
    and <a href="https://www.datavismasterclass.org/fr/paris">Paris (14 April)</a>. -->
    <!-- Learn from decades of experience in just a few hours. -->
    <!-- <span class="label">When:</span>
    <span class="detail">Tuesday, 8 April (<a :href="url">9am&ndash;6pm GMT</a>)</span>.
    <span class="label">Where:</span>
    <span class="detail">Cambridge UK &mdash; and online.</span> -->
  </p>
  <!--<router-link to="/2023/Registration" title="Click to register">-->
  <RouterLink to="/2025/Registration"><HeroImage hero-image="Masterclass.jpeg" /></RouterLink>
  <!--</router-link>-->
  <RegisterNowWrapper />
  <h4>Masterclass vs. Workshop explained:</h4>
  <ul>
    <li>
      <strong>Masterclass:</strong> A focused, one-day course on foundational visualization
      techniques.
    </li>
    <li>
      <strong>Workshop:</strong> A three-day deep-dive into visualizations that are driving
      breakthroughs in the life sciences.
    </li>
    <li>
      <strong>Most attendees register for both:</strong> It's the best way to engage with the unique
      VIZBI community, to get valuable input on your work, and to learn about cutting-edge advances
      in DataVis and across all life sciences.
    </li>
  </ul>

  <p>
    <b>Who is the Masterclass for?</b> The course is designed to be useful for anyone working with
    large, complex datasets. This includes scientists working in any field, as well as programmers,
    designers, and communicators. The course also covers data-driven storytelling, so will be
    valuable for designers, visual artists, as well as marketing and communications professionals.
  </p>

  <p>
    <b>What will I learn?</b> You'll gain insights into: <span>(1)</span> Visualization principles;
    <span>(2)</span> Design and typography; <span>(3)</span> Web-based visualization;
    <span>(4)</span> Complexity and aesthetics; <span>(5)</span> Data-driven, visual storytelling.
    You also get 24 hours of video content from
    <RouterLink to="/Videos/Masterclass">other world-leading practitioners</RouterLink>.
  </p>

  <p>
    <b>How does it work?</b> Join us in Cambridge UK—or online. Virtual participants can access a
    live stream and ask questions in real time. All participants get access to on-demand videos
    after the event, including content from
    <RouterLink to="/Videos/Masterclass">previous Masterclasses</RouterLink>.
  </p>

  <!--
  <h4>What will I learn?</h4>
  <p>This course provides you the opportunity to:</p>
  <ul>
    <li>
      Learn key principles of human visual perception, and get an overview of current DataVis techniques and tools;
    </li>
    <li>
      Learn general strategies for creating insightful visual representations from large, complex, multidimensional
      datasets;
    </li>
    <li>Learn how to create clear and compelling visualizations for communication;</li>
    <li>Learn how to choose design elements and attributes, with an emphasis on typography;</li>
    <li>Learn how to create powerful, interactive visualizations using cutting-edge GPU programming methods;</li>
    <li>Learn about newly emerging strategies for visualizing time-oriented data;</li>
    <li>Connect with leading data visualization experts and practitioners.</li>
  </ul>
-->

  <!-- <h4>What do I need to know to participate?</h4>
  <p>
    The VIZBI Masterclass has been designed to be accessible by anyone working with data on a
    regular basis. -->
  <!--Some
    limited programming experience is recommended for the session on GPU-driven interactive visualization.-->
  <!-- </p> -->

  <!-- <h4 id="both">
    <a name="both" />I'm already attending the VIZBI Workshop - why should I go to the Masterclass?
  </h4>
  <p style="display: inline">
    The Masterclass is a satellite event to the
    <router-link to="/2025">VIZBI Workshop</router-link> (April 9–11). The Masterclass and Workshop
    are quite different, with little overlap in material. The Masterclass focuses on training in
    general methods, while the Workshop focuses on visualizations that address specific biological
    research challenges. In the Masterclass, the two morning sessions cover some principles that may
    be assumed knowledge for some of the Workshop talks. The afternoon sessions focuses on DataVis
    topics that will not be specifically addressed during the Workshop. So, if you have the time,
    money, and motivation, it can certainly make sense to attend both events. At recent VIZBI
    meetings, many participants and speakers attended both events.
  </p> -->

  <p><b>Further questions?</b> Contact us at <Email to="masterclass" />.</p>
  <p />
  <!--<p><ButtonBespoke :outstanding="true" @click="$router.push('/2023/Registration')">Register now</ButtonBespoke></p>-->
</template>

<script setup>
//import ButtonBespoke from "@/components/ButtonBespoke";
import Email from "@/components/Email";
import HeroImage from "@/components/HeroImage.vue";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";
const timeanddate = "https://www.timeanddate.com/worldclock/meetingtime.html";
const timezones = "p1=37&p2=1234&p3=179&p4=224";
const url = `${timeanddate}?day=8&month=4&year=2025&${timezones}`;
</script>

<style scoped>
span.buttonish {
  color: white;
  background-color: var(--brand-color);
  padding: 2px 4px;
  border-radius: 4px;
}
img {
  width: 100%;
}
p button {
  margin-top: 16px;
  margin-bottom: 16px;
}
h4 {
  margin-bottom: 0rem;
}
:is(p, ul) {
  margin-top: 0.35rem; /* Adjust margin for number alignment */
  margin-bottom: 1rem; /* Adjust margin for number alignment */
}
ul {
  list-style-position: outside; /* Numbers outside the content box */
  margin-left: 1rem; /* Adjust margin for number alignment */
  padding-left: 0; /* No extra padding needed */
}
li {
  text-indent: 0; /* First line aligns naturally with numbers */
  line-height: 1.3;
  margin-bottom: 0.1rem;
}
li strong {
  font-style: italic;
  font-weight: 800;
}
p span {
  font-style: italic; /* Adds subtle emphasis */
  font-weight: 700; /* Slightly bold but not heavy */
  margin-right: 0.25em; /* Adds spacing between the number and text */
}
p span.label {
  all: unset;
  font-style: italic; /* Adds subtle emphasis */
  font-weight: 700; /* Highlight "When" and "Where" */
  margin-right: 0.5em; /* Space after labels */
}
p span.detail {
  all: unset;
}
a span {
  font-weight: normal;
  font-style: normal;
  /* opacity: 0.7; */
}
</style>
