<template>
  <div v-if="timeZoneDiffers && timeZoneSelected" id="timezone">
    <!-- only show if the local and conference time zones differ -->
    Time zone:
    <Dropdown
      :placeholder="timeZoneSelected"
      :options="[timeZoneConference, timeZoneLocal].sort()"
      :selected="timeZoneSelected"
      :tag="false"
      @update="switchTimeZone" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["timeZoneSelected", "daySelected"]);
import timezoneHistory from "@/assets/history.json";
const {DateTime} = require("luxon");
const timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
import Dropdown from "@/components/Dropdown.vue";
import {useUrlHash} from "@/composables/useSetUrlHash";
const {setUrlHash} = useUrlHash();

import {computed} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
const timeZoneConference = computed(() => {
  const year = route.params.year;
  if (year) return timezoneHistory.years[year];
  return "";
});

// Function to switch timezone
const emit = defineEmits(["update"]);
function switchTimeZone(newTimeZone) {
  log(`switchTimeZone(${newTimeZone})`);
  emit("update", newTimeZone);
  localStorage.setItem("timeZoneSelected", newTimeZone);
  updateUrlHash(props.daySelected, newTimeZone);
}

// Set initial state
const timeZoneDiffers = computed(() => {
  log(`Testing if user and conference timezones are different`);
  if (!timeZoneConference.value) return false; // Early return
  let localTime = DateTime.local(); // Get current time in local timeZone
  log(`timeZoneDiffers: localTime (${localTime})`);
  let timeInConferenceZone = localTime.setZone(timeZoneConference.value);
  // Rezone to conference without changing timestamp
  log(`timeZoneDiffers: timeInConferenceZone (${timeInConferenceZone})`);
  return timeInConferenceZone.toString() !== localTime.toString();
});
(function () {
  log("Immediately invoke function to set initial TimeZone based on URL hash");
  let parameters = route.hash.replace(/^#/, "").split("&");
  if (route.name === "Program" && parameters[0] && !parameters[0].match(/-/)) {
    parameters.shift(); // remove day from parameters array
  }
  if (parameters[0] && parameters[0].match(/-/)) {
    log(`Overwrite time zone in URL with local time zone`);
    emit("update", timeZoneLocal);
    localStorage.setItem("timeZoneSelected", timeZoneLocal);
    return;
  } else if (localStorage.getItem("timeZoneSelected")) {
    const newTimeZone = localStorage.getItem("timeZoneSelected");
    log(`Set time zone from localStorage: ${newTimeZone}`);
    emit("update", newTimeZone);
    updateUrlHash(props.daySelected, newTimeZone);
  } else {
    log(`Set time zone to conference time zone`);
    emit("update", timeZoneConference.value);
  }
})();

// Update URL
function updateUrlHash(day, timeZone) {
  log(`updateUrlHash(${day}, ${timeZone})`);
  // if (!day) day = props.daySelected; // not needed?
  //if (!timeZone) timeZone = props.timeZoneSelected; // not needed?
  //
  if (timeZone === timeZoneConference.value) {
    setUrlHash(day);
  } else {
    setUrlHash(day, timeZone.replace("/", "-"));
  }
}

import {watch} from "vue";
watch(
  () => props.daySelected,
  (newDaySelected) => {
    log(`daySelected has changed; updating URL hash`);
    return updateUrlHash(newDaySelected, props.timeZoneSelected);
  }
);

// Not quite sure of the case, but pretty sure it's needed
watch(route, (to, from) => {
  log(`program: change from '${from.name}' to '${to.name}'`);
  if (to.name.match(/program/i) && !from.name.match(/program/i)) {
    if (props.daySelected) {
      log(`A day is selected - updating URL hash`);
      updateUrlHash(props.daySelected, props.timeZoneSelected);
    } else {
      log(`No day selected - reset view`);
    }
  }
});
</script>

<style scoped>
#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
.narrow #timezone,
.narrow #timezone {
  margin-top: 0px; /* same as App.vue/h3 */
  margin-bottom: 14px; /* same as App.vue/h3 */
}
</style>
