<template>
  <div class="pricing-table">
    <Slider
      v-model:selectedOption="attendance"
      :options="[...attendanceTypes]"
      :abbreviations="abbreviations" />
    <div class="discount-buttons div-needed-for-equal-borders">
      <p>Available discounts (select all that apply):</p>
      <ButtonRow
        v-model:selectedOptions="selectedDiscounts"
        :options="discountTypes"
        :abbreviations="abbreviations" />
    </div>
    <FeeTable
      v-for="(event, eventName) in events"
      :key="eventName"
      :title="eventName"
      :data="addDiscountedFees(event, attendance)"
      :abbreviations="abbreviations" />
  </div>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
const props = defineProps(["options"]);
import {ref, computed} from "vue";
import history from "@/assets/history.json";
import Slider from "@/components/App/Slider";
import ButtonRow from "@/components/App/ButtonRow";
import FeeTable from "@/components/Registration/FeeTable";
// const {events} = computed(() => props.options || {});
const events = computed(() => props.options?.events ?? []);
const discounts = computed(() => props.options?.discounts ?? []);
// const discounts = computed(() => {
//   const discounts = props.options?.discounts ?? {};
//   log(`Calculator: discounts = ${JSON.stringify(discounts)}`);
//   return discounts;
// });

const abbreviations = computed(() => props.options?.abbreviations ?? []);
const isEarlyBirdPast = computed(() => props.options?.isEarlyBirdPast ?? []);
const attendanceTypes = new Set();
{
  for (const [eventName, eventData] of Object.entries(events.value)) {
    if (typeof eventData !== "object" || eventData === null) continue;
    for (const [key, value] of Object.entries(eventData)) {
      if (typeof value !== "object" || value === null) continue;
      attendanceTypes.add(key);
    }
  }
  if (attendanceTypes.sizes === 0) throw new Error(`No attendanceTypes found`);
}
const firstAttendanceType = attendanceTypes.values().next().value; // painful!
const attendance = ref(firstAttendanceType); // get first type

function sortDiscountTypes(keys) {
  return keys.sort((a, b) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();

    if (aLower.includes("regional") && !bLower.includes("regional")) return -1;
    if (!aLower.includes("regional") && bLower.includes("regional")) return 1;

    if (aLower.includes("early") && !bLower.includes("early")) return -1;
    if (!aLower.includes("early") && bLower.includes("early")) return 1;

    return 0; // Keep other items in any order
  });
}

const discountTypes = computed(() => {
  const keys = sortDiscountTypes(Object.keys(discounts.value));
  if (!isEarlyBirdPast.value) return keys;
  return keys.filter((key) => !key.toLowerCase().match(/early\s*bird/));
});
const selectedDiscounts = ref(
  isEarlyBirdPast.value ? discountTypes.value.slice(0, 1) : discountTypes.value.slice(0, 2)
);

const calculateDiscountedFee = (fullFee) => {
  const additive = history.discountCalculation === "additive" ? true : false;
  let totalDiscountRate = additive ? 0 : 1;

  // Iterate through discounts and sum applicable rates
  for (const [key, rate] of Object.entries(discounts.value)) {
    if (!selectedDiscounts.value.includes(key)) continue;
    if (additive) {
      totalDiscountRate += rate; // Sum rates
    } else {
      totalDiscountRate *= 1 - rate; // multiplicative by default
    }
  }
  const effectiveRate = additive ? totalDiscountRate : 1 - totalDiscountRate;
  return Math.round(fullFee * (1 - effectiveRate));
};

function addDiscountedFees(event, attendance) {
  //  Adds discounted fees to the given object by calculating the discounts for each full fee.
  log(`Registration: event = (${JSON.stringify(event)})`);
  log(`Registration: attendance = (${JSON.stringify(attendance)})`);
  if (!event[attendance]) {
    log(`Registration: addDiscountedFees = ${JSON.stringify(event)}`);
    return event;
  }
  const fees = event[attendance];
  log(`Registration: fees = (${JSON.stringify(fees)})`);
  if (typeof fees !== "object") {
    log("Registration: 'fees' not an object.");
    return fees;
  }
  const keys = Object.keys(fees);
  if (keys.length !== 1) {
    log("Registration: 'fees' has <> 1 property.");
    return fees;
  }
  const key = keys[0]; // The single property name
  if (typeof fees[key] !== "object") {
    ("Registration: 'fees[key]' not object.");
    return fees;
  }
  const discountedFees = {}; // Create a new object for discounted fees
  for (const [key, fullFee] of Object.entries(fees[key])) {
    if (typeof fullFee !== "number") {
      log(`Registration: '${fullFee}' not a number`);
      return fees;
    }
    discountedFees[key] = calculateDiscountedFee(fullFee);
  }
  const addDiscountedFees = {...fees, "Discounted Fee": discountedFees};
  log(`Registration: addDiscountedFees = ${JSON.stringify(addDiscountedFees)}`);
  return addDiscountedFees; // Add the discounted fees to the original object
}
</script>

<style scoped>
:is(*, *::before, *::after) {
  box-sizing: border-box;
}
.pricing-table {
  display: flex;
  flex-direction: column;
  /* gap: 16px; Space between items */
  background-color: #dfdfdf;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 8px;
}
.pricing-table > * {
  border-bottom: 2px solid #f4f4f4; /* Add border to separate items */
  margin-bottom: 16px; /* Add spacing after each element */
  padding-bottom: 16px; /* Ensure padding is added before the border */
}
.pricing-table > *:first-child {
  margin-top: 16px; /* Matches the bottom spacing of other elements */
}
.pricing-table > *:last-child {
  border-bottom: none; /* Remove border for the last child */
  margin-bottom: 0; /* Prevent extra spacing after the last child */
  padding-bottom: 0; /* Remove padding as well */
}
p {
  text-align: center;
  margin-top: 0px;
  font-size: 1.1rem;
  font-weight: 300;
  color: rgb(148, 148, 148);
}
:is(.unlimited, .wide, .medium) p {
  font-size: 1.3rem;
}
</style>
