<template>
  <a name="top" />
  <div id="loading-indicator" :style="{display: iframeVisible ? 'none' : 'flex'}">Loading...</div>
  <iframe
    ref="iframeElement"
    :src="registrationUrl"
    title="VIZBI 2024 Registration via Swoogo"
    :style="{display: iframeVisible ? 'block' : 'none'}"
    width="100%"
    height="1200"
    @load="checkThirdPartyCookies" />
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// import Email from "@/components/Email.vue";
import {ref} from "vue";
const registrationUrl = "https://iscb.swoogo.com/vizbi2024/";
const iframeElement = ref(null);
const iframeVisible = ref(false);
// check if iframe is successful
function checkThirdPartyCookies() {
  if (!iframeElement.value) return;
  try {
    const iframeDoc = iframeElement.value.contentDocument || iframeElement.value.contentWindow;
    log(`iframeDoc = ${JSON.stringify(iframeDoc, null, 4)}`);
  } catch (error) {
    log(`iframe blocked: ${JSON.stringify(error, null, 4)}`);
    if (error.stack && error.stack.includes("localhost")) {
      log(`Running on localhost; Open registration form directly`);
      window.location.href = registrationUrl;
      return;
    } else if (error.sourceURL) {
      log(`Running on Safari; Open registration form directly`);
      window.location.href = registrationUrl;
      return;
    }
    iframeVisible.value = true;
  }
}
</script>

<style scoped>
#loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  font-size: 2em;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
  z-index: 1000; /* Make sure it's above other elements */
  animation: colorCycle 2s linear infinite;
}

@keyframes colorCycle {
  0% {
    background-color: var(--background-color);
  }
  50% {
    background-color: var(--background-color-subtle);
  }
  100% {
    background-color: var(--background-color);
  }
}
</style>
