// Determines regional discount via IP lookup, falling back to browser settings.
// Uses country income data and caches results to minimize lookups.

const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
import countries from "/src/assets/general/regions-to-countries.json";
import incomeCategories from "/src/assets/general/countries-to-income.json";
let discount = null; // use this variable to remember previous calls

const discounts = {
  "Low income": 0.95,
  "Lower middle income": 0.8,
  "Upper middle income": 0.4,
  "High income": 0
};

async function fetchCountryByIP() {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data.country_name || "";
  } catch (error) {
    log("IP lookup failed: " + error);
    return "";
  }
}

function fetchCountryByBrowserSettings() {
  if (!Intl) return "";
  let region = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return countries[region] || "";
}

export async function useRegionalDiscount() {
  if (discount !== null) return discount; // return if already determined
  let country = await fetchCountryByIP();
  if (!country) country = fetchCountryByBrowserSettings();
  log(`useRegionalDiscount: country = ${country}`);
  const category = incomeCategories[country] || "High income";
  log(`useRegionalDiscount: category = ${category}`);
  discount = discounts[category] || 0;
  log(`useRegionalDiscount: discount = ${discount}`);
  return discount;
}
