<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <fieldset>
    <legend>Archive</legend>
    <menu>
      <PrimaryItem
        v-for="year in sortedYears"
        :key="`navPrim-${year}`"
        :to="year"
        :selected="year === selected"
        >{{ year }}</PrimaryItem
      >
    </menu>
  </fieldset>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({
  years: {default: []},
  selected: {default: ""}
});
import PrimaryItem from "@/components/Navigation/Primary/Item.vue";
// My right-align hack: https://stackoverflow.com/q/72461482
import {ref} from "vue";
const sortedYears = ref(props.years);
(function () {
  sortedYears.value.reverse();
  const length = sortedYears.value.length;
  const groups = Math.ceil(length / 4);
  for (let i = 0; i < groups; i++) {
    const startIndex = Math.max(0, length - (i + 1) * 4);
    const endIndex = length - i * 4;
    const group = sortedYears.value.slice(startIndex, endIndex);
    log(`sortedYears: group = ${group}`);
    group.reverse();
    log(`sortedYears: reversed = ${group}`);
    sortedYears.value.splice(startIndex, group.length, ...group);
  }
})();
sortedYears.value.reverse();
</script>

<style scoped>
/* * {
  background-color: rgb(0 100 0 / 0.1) !important;
} */
fieldset {
  height: 100%;
  min-width: 0; /* prevents from overflowing */
  padding: 0;
  margin: 0.3rem 0 0.6rem 0;
  border: 1px solid transparent;
  border-top-color: var(--primary-navigation-color);
  font-weight: 600;
}
.lightMode fieldset {
  border-top-color: var(--text-color-less-subtle);
}
legend {
  color: var(--primary-navigation-color);
  margin: auto; /*to center*/
  padding: 0 12px;
}
.lightMode legend {
  color: var(--text-color-less-subtle);
}
menu {
  height: 100%;
  width: auto;
  display: grid;
  direction: rtl; /* right-align hack: https://stackoverflow.com/q/72461482 */
  grid-template-columns: repeat(4, auto);
  justify-content: space-between; /* space-evenly */
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr)); /* Evenly space rows vertically */
  grid-auto-rows: minmax(0, 1fr);
  /* auto not 1fr: https://stackoverflow.com/q/70770416*/
  /* grid-auto-rows: min-content; */
  /* row-gap: 1.4rem; */
  /* row-gap: 18%; */
  padding: 0; /* critical for flush right */
  margin-top: 1rem;
  font-weight: 700;
}
menu :deep(a) {
  color: var(--primary-navigation-color); /* override default link color */
}
.lightMode menu :deep(a) {
  color: var(--text-color-less-subtle);
  text-shadow: none;
}
/* menu :deep(a:focus), */
menu :deep(a:hover) {
  color: var(--primary-navigation-color); /* clobber default link color */
  background-color: var(--focus-background-color);
  border-radius: 0.4rem;
}
</style>
