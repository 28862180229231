<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <li v-if="to" :class="{selected: selected}">
    <router-link :to="`/${to}`">
      <slot />
    </router-link>
  </li>
  <li v-else :class="{selected: selected}">
    <a>
      <!-- "Archive" has no link but needs '<a></a>' for consistent styling -->
      <slot />
    </a>
  </li>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// eslint-disable-next-line
const props = defineProps({
  to: {default: ""},
  selected: {default: false}
});
import {watch} from "vue";
watch(
  props,
  () => {
    log(`[to, selected] = [${props.to}, ${props.selected}]`);
  },
  {deep: true}
);
</script>

<style scoped>
li {
  list-style-type: none;
  text-align: right;
  /* z-index: 2; */
  padding: 0;
  margin: 0;
  /* line-height: 1.4rem; was: 1.5em */
  /* font-weight: 700; */
  letter-spacing: 0.03em;
  /* text-align: right; */
  /* overflow:   visible; */
  vertical-align: text-bottom; /* needed? improves vertical alignment */
  text-shadow: none;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
}
a {
  /* flex: 1;  expand to fill container */
  display: inline-flex; /* needed for arrow; inline-flex = problems  */
  justify-content: flex-end; /* needed for right align */
  align-content: top;
  margin: 0;
  padding: 0.25rem;
  /* outline-offset: 0.25rem; prevent outline clipping */
  box-sizing: border-box;
}
a:hover {
  text-decoration: none;
}
/* color while hovering or selected */
li.selected a {
  color: var(--brand-color) !important; /* override colors from parent */
}
li.selected a:hover {
  color: var(--brand-hover-color) !important; /* override colors from parent */
}
</style>
