<!-- https://www.techighness.com/post/get-user-country-and-region-on-browser-with-javascript-only/ -->
<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <span>{{ fee() }}</span>
</template>

<script setup>
const log = (msg) => process.env.VUE_APP_DEV && console.log(); // eslint-disable-line
// https://www.techighness.com/post/get-user-country-and-region-on-browser-with-javascript-only/
import countries from "/src/assets/general/regions-to-countries.json";
// https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html
import incomeCategories from "/src/assets/general/countries-to-income.json";
import {inject} from "vue";
// const selectedYear = inject("selectedYear");
const selectedTimeZone = inject("selectedTimeZone");

import {computed} from "vue";
const country = computed(() => {
  if (Intl) {
    let region = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return countries[region] || "";
  } else {
    console.log("Intl not defined");
    return "";
  }
});

const incomeCategory = computed(() => {
  if (country.value) {
    return incomeCategories[country.value] || "";
  } else {
    console.log("country.value not defined");
    return "";
  }
});

// const props = defineProps({
//   category: "",
//   eventName: "",
//   fees: () => {},
//   late: false,
//   iscbMember: false
// });

const props = defineProps(["category", "eventName", "fees", "late", "iscbMember"]);

function currencySymbol() {
  log(`selectedTimeZone = ${JSON.stringify(selectedTimeZone.value)}`);
  if (typeof selectedTimeZone.value !== "string") return "?timeZone?";
  if (selectedTimeZone.value.startsWith("America")) return "$";
  if (selectedTimeZone.value.startsWith("Europe")) return "€";
  return "?";
}
function fee() {
  if (!props || !props.eventName || !props.fees) return;
  if (props.fees[props.eventName]) return feeLegacy();
  log(`incomeCategory.value = ${incomeCategory.value}`);
  let discount = 0;
  let iscb_discount = 0.15;
  if (incomeCategory.value && props.eventName && props.eventName.includes("Masterclass")) {
    // apply discounts for low-income countries
    //fees[props.eventName][props.category] =
    if (incomeCategory.value === "Low income") {
      discount = 0.9 - iscb_discount; /* 90% for ISCB members */
    } else if (incomeCategory.value === "Lower middle income") {
      discount = 0.6 - iscb_discount; /* 60% for ISCB members */
    } else if (incomeCategory.value === "Upper middle income") {
      discount = 0.3 - iscb_discount; /* 30% for ISCB members */
    }
  }
  if (props.iscbMember) {
    discount += iscb_discount;
  }
  log(`discount = ${discount}`);
  log(`props.category = ${props.category}`);
  log(`props.fees = ${JSON.stringify(props.fees, null, 2)}`);
  //return '€' + fees[props.eventName][props.category] * (1 - discount)
  //return fees[props.eventName]["Student"];
  let fee = (1 - discount) * props.fees[props.category];
  //if (props.late) fee += props.fees[props.eventName]["late"];
  return currencySymbol() + Math.round(fee);
}
function feeLegacy() {
  if (!props || !props.eventName) return;
  log(`incomeCategory.value = ${incomeCategory.value}`);
  let discount = 0;
  let iscb_discount = 0.15;
  if (incomeCategory.value && props.eventName && props.eventName.includes("Masterclass")) {
    // apply discounts for low-income countries
    //fees[props.eventName][props.category] =
    if (incomeCategory.value === "Low income") {
      discount = 0.9 - iscb_discount; /* 90% for ISCB members */
    } else if (incomeCategory.value === "Lower middle income") {
      discount = 0.6 - iscb_discount; /* 60% for ISCB members */
    } else if (incomeCategory.value === "Upper middle income") {
      discount = 0.3 - iscb_discount; /* 30% for ISCB members */
    }
  }
  if (props.iscbMember) {
    discount += iscb_discount;
  }
  log(`discount = ${discount}`);
  log(`props.eventName = ${props.eventName}`);
  log(`props.category = ${props.category}`);
  log(`props.fees = ${JSON.stringify(props.fees, null, 2)}`);
  //return '€' + fees[props.eventName][props.category] * (1 - discount)
  //return fees[props.eventName]["Student"];
  let fee = (1 - discount) * props.fees[props.eventName][props.category];
  if (props.late) fee += props.fees[props.eventName]["late"];
  return currencySymbol() + Math.round(fee);
}
</script>
