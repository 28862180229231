<template>
  <div class="table" :style="tableStyle">
    <!-- Header Row -->
    <div class="row header">
      <div v-for="(header, index) in headers" :key="index" class="cell header-cell">
        {{ header }}
      </div>
    </div>
    <!-- Data Rows -->
    <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="row">
      <div
        v-for="(cell, columnIndex) in row"
        :key="columnIndex"
        class="cell"
        :style="isHighlighted(columnIndex) ? highlightStyle : null">
        {{ cell }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps(["headers", "rows", "highlightedColumns", "highlightStyle"]);

// Generate dynamic named columns based on the number of headers
const columnNames = computed(() => {
  const numColumns = props.headers.length;
  return Array.from({length: numColumns}, (_, i) => `[column-${i + 1}] auto`).join(" ");
});

// Generate the grid-template-columns dynamically
const tableStyle = computed(() => ({
  gridTemplateColumns: columnNames.value,
  width: "100%",
  overflowX: "auto"
}));

// Helper function to check if a cell is highlighted
const isHighlighted = (columnIndex) => {
  return props.highlightedColumns.includes(columnIndex);
};
</script>

<style scoped>
.table {
  --cell-padding: 0.22rem;
  --even-row-bg: rgba(0, 0, 0, 0.03);
  --odd-row-bg: rgba(0, 0, 0, 0);

  display: grid;
  grid-auto-rows: auto;
  gap: 0px;
  width: 100%;
  font-size: 1.1rem;

  /* Apply dynamically generated named columns */
  grid-template-columns: var(--column-names);
}

.row {
  display: contents;
}

/* Assign cells to named columns dynamically */
.cell:nth-child(1) {
  grid-column: column-1;
}
.cell:nth-child(2) {
  grid-column: column-2;
}
.cell:nth-child(3) {
  grid-column: column-3;
}
.cell:nth-child(4) {
  grid-column: column-4;
}

/* Apply styles to header */
.header {
  font-weight: bold;
  font-style: italic;
  color: #333;
}

.header-cell {
  font-weight: bold;
  color: #000;
  text-align: center;
}

.header-cell:first-child {
  font-style: normal;
  text-transform: uppercase;
  text-align: left;
}

.cell {
  padding: var(--cell-padding);
  text-align: center;
  color: #555;
}

/* Ensure first column (data rows) are left-aligned */
.cell:first-child {
  text-align: left;
}

/* Apply even/odd row background colors */
.row:nth-child(n + 2):nth-child(even) .cell {
  background-color: var(--even-row-bg);
}
.row:nth-child(n + 2):nth-child(odd) .cell {
  background-color: var(--odd-row-bg);
}
</style>
